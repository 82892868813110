import React, { useEffect } from 'react';
import { TextField, Button, Grid, MenuItem, Typography, Tooltip } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';

const EmailTemplateForm = ({
  emailTemplateFormData,
  handleEmailTemplateFormChange,
  handleEmailTemplateFormSubmit,
  isEditingEmailTemplate,
  services = []
}) => {
  const { userRole, appAccess } = useAuth(); // Access user role and permissions
  const permissions = appAccess['booking'] || {}; // Assuming this is related to booking
  const canCreate = permissions.can_create || userRole === 'superadmin'; // Create permission
  const canEdit = permissions.can_edit || userRole === 'superadmin'; // Edit permission

  // Determine if the user has permission to either create or edit
  const canSubmitForm = isEditingEmailTemplate ? canEdit : canCreate;

  useEffect(() => {
    console.log('EmailTemplateForm rendered with data:', emailTemplateFormData);
    console.log('Services:', services);
  }, [emailTemplateFormData, services]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(`Field ${name} changed to:`, value);
    
    if (typeof handleEmailTemplateFormChange === 'function') {
      handleEmailTemplateFormChange(event);
    } else {
      console.error('handleEmailTemplateFormChange prop is not a function. Unable to update form state.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted with data:', emailTemplateFormData);
    if (typeof handleEmailTemplateFormSubmit === 'function') {
      handleEmailTemplateFormSubmit(e);
    } else {
      console.error('handleEmailTemplateFormSubmit prop is not a function. Form submission not handled.');
    }
  };

  if (!emailTemplateFormData) {
    return <Typography color="error">Error: Email template data is missing.</Typography>;
  }

  const availableVariables = [
    '{{customer_name}}',
    '{{appointment_date}}',
    '{{appointment_time}}',
    '{{service_name}}',
    '{{booking_id}}',
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Service"
            name="service"
            value={emailTemplateFormData.service || ''}
            onChange={handleChange}
            required
            disabled={!canSubmitForm} // Disable if no permission
          >
            {services.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Channel"
            name="channel"
            value={emailTemplateFormData.channel || ''}
            onChange={handleChange}
            required
            disabled={!canSubmitForm} // Disable if no permission
          >
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="whatsapp">WhatsApp</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Status"
            name="status"
            value={emailTemplateFormData.status || ''}
            onChange={handleChange}
            required
            disabled={!canSubmitForm} // Disable if no permission
          >
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="confirmed">Confirmed</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
          </TextField>
        </Grid>
        {emailTemplateFormData.channel === 'email' && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Subject"
              name="subject"
              value={emailTemplateFormData.subject || ''}
              onChange={handleChange}
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Tooltip title={`Available variables: ${availableVariables.join(', ')}`} placement="top-start">
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Body"
              name="body"
              value={emailTemplateFormData.body || ''}
              onChange={handleChange}
              required
              helperText="You can use variables like {{customer_name}} in the body. Hover to see all available variables."
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          {canSubmitForm && ( // Show the button only if the user has permission to submit
            <Button type="submit" variant="contained" color="primary">
              {isEditingEmailTemplate ? 'Update Template' : 'Create Template'}
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default EmailTemplateForm;
