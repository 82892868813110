import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useAuth } from '../../../contexts/AuthContext';

const HolidayList = ({ holidays, handleEditHoliday, handleDeleteHoliday, services }) => {
  const { userRole, appAccess } = useAuth(); // Access user role and permissions
  const permissions = appAccess['booking'] || {}; // Assuming this relates to booking permissions
  const canEdit = permissions.can_edit || userRole === 'superadmin'; // Edit permission
  const canDelete = permissions.can_delete || userRole === 'superadmin'; // Delete permission

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Services</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {holidays.map((holiday) => (
            <TableRow key={holiday.id}>
              <TableCell>{holiday.name}</TableCell>
              <TableCell>{holiday.start_date}</TableCell>
              <TableCell>{holiday.end_date}</TableCell>
              <TableCell>
                {holiday.services
                  .map((serviceId) => {
                    const service = services.find((s) => s.id === serviceId);
                    return service ? service.name : '';
                  })
                  .join(', ')}
              </TableCell>
              <TableCell>
                {canEdit && (
                  <IconButton onClick={() => handleEditHoliday(holiday)} color="primary">
                    <EditIcon />
                  </IconButton>
                )}
                {canDelete && (
                  <IconButton onClick={() => handleDeleteHoliday(holiday.id)} color="error">
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HolidayList;
