import React, { useState, useEffect } from 'react';
import { 
  TextField, Box, Typography, Grid, Dialog,
  DialogTitle, DialogContent, IconButton, Tooltip, Zoom,
  Popover
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Send as SendIcon, Close as CloseIcon, AttachFile as AttachIcon, EmojiEmotions, Assignment as AssignmentIcon } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import EmojiPicker from 'emoji-picker-react';
import CarSearchPage from './CarSearchPage';
import { CarAttachmentList, SendButton } from './SendComponents';

const SendMessageForm = ({ onSendMessage, unsentMessage }) => {
  const [message, setMessage] = useState('');
  const [attachedCars, setAttachedCars] = useState([]);
  const [isCarSearchOpen, setIsCarSearchOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [includeRegistrationForm, setIncludeRegistrationForm] = useState(false);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);

  useEffect(() => {
    if (unsentMessage && unsentMessage.sent === false) {
      setMessage(unsentMessage.bot_message || '');
      setAttachedCars(unsentMessage.car_stocks || []);
    }
  }, [unsentMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidMessage()) {
      setIsSending(true);
      await onSendMessage({
        text: message,
        cars: attachedCars,
        include_registration_form: includeRegistrationForm
      });
      setMessage('');
      setAttachedCars([]);
      setIsExpanded(false);
      setIsSending(false);
      setIncludeRegistrationForm(false);
    }
  };

  const isValidMessage = () => {
    if (!message.trim()) {
      return false; // Empty messages are not allowed
    }
    if (attachedCars.length > 0 || includeRegistrationForm) {
      return message.trim().length > 0; // Cars and registration form require text
    }
    return true; // Text alone is valid
  };

  const handleOpenCarSearch = () => setIsCarSearchOpen(true);
  const handleCloseCarSearch = () => setIsCarSearchOpen(false);
  const handleSelectCars = (selectedCars) => {
    setAttachedCars(selectedCars);
    setIncludeRegistrationForm(false);
    handleCloseCarSearch();
  };
  const handleRemoveCar = (vehicleid) => {
    setAttachedCars(attachedCars.filter(car => car.vehicleid !== vehicleid));
  };

  const toggleRegistrationForm = () => {
    if (attachedCars.length === 0) {
      setIncludeRegistrationForm(!includeRegistrationForm);
    }
  };

  const isSubmitDisabled = () => {
    return !isValidMessage() || isSending;
  };

  const handleOpenEmojiPicker = (event) => {
    setEmojiAnchorEl(event.currentTarget);
  };

  const handleCloseEmojiPicker = () => {
    setEmojiAnchorEl(null);
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, mb: 2 }}>
      <motion.div layout>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onFocus={() => setIsExpanded(true)}
                multiline
                rows={isExpanded ? 3 : 1}
                InputProps={{
                  endAdornment: (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="Add emoji" arrow>
                        <IconButton onClick={handleOpenEmojiPicker}>
                          <EmojiEmotions />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Attach cars" arrow>
                        <IconButton onClick={handleOpenCarSearch} disabled={includeRegistrationForm}>
                          <AttachIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={includeRegistrationForm ? "Remove registration form" : "Add registration form"} arrow>
                        <IconButton onClick={toggleRegistrationForm} color={includeRegistrationForm ? "primary" : "default"} disabled={attachedCars.length > 0}>
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Send message" arrow>
                        <SendButton 
                          type="submit" 
                          disabled={isSubmitDisabled()}
                          className={isSending ? 'sending' : ''}
                        >
                          <SendIcon />
                        </SendButton>
                      </Tooltip>
                    </Box>
                  ),
                }}
              />
            </motion.div>
          </Grid>
          <AnimatePresence>
            {attachedCars.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Attached Cars:
                </Typography>
                <CarAttachmentList cars={attachedCars} onRemoveCar={handleRemoveCar} />
              </Grid>
            )}
          </AnimatePresence>
          {includeRegistrationForm && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary" gutterBottom>
                Registration form will be included with this message.
              </Typography>
            </Grid>
          )}
        </Grid>
      </motion.div>

      <Dialog 
        open={isCarSearchOpen} 
        onClose={handleCloseCarSearch} 
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={300}
      >
        <DialogTitle>
          Select Cars
          <IconButton
            aria-label="close"
            onClick={handleCloseCarSearch}
            sx={{ position: 'absolute', right: 10, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CarSearchPage onSelectCars={handleSelectCars} initialSelectedCars={attachedCars} />
        </DialogContent>
      </Dialog>

      <Popover
        open={Boolean(emojiAnchorEl)}
        anchorEl={emojiAnchorEl}
        onClose={handleCloseEmojiPicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <EmojiPicker onEmojiClick={handleEmojiClick} />
      </Popover>
    </Box>
  );
};

export default SendMessageForm;