import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, List, ListItem, ListItemText, ListItemAvatar, 
  Avatar, Chip, Dialog, DialogContent, DialogTitle, IconButton, 
  Switch, FormControlLabel, TextField, InputAdornment, Fade,
  Grid, Paper, useTheme, useMediaQuery
} from '@mui/material';
import { MessageCircle, User, Calendar,TagIcon, X as CloseIcon, Search } from 'lucide-react';
import ChatHistoryDetail from './ChatHistoryDetail';
import { useAuth } from '../contexts/AuthContext';

const Chats = ({ chats, onChatClick }) => {
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [localChats, setLocalChats] = useState(chats);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredChats, setFilteredChats] = useState(chats);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const { userRole, appAccess } = useAuth();  // Access permissions from AuthContext
  const permissions = appAccess['ai_app'] || {};  // Extract booking-related permissions
  const canView = permissions.can_view || userRole === 'superadmin';  // Allow superadmins unrestricted access
  const canCreate = permissions.can_create || userRole === 'superadmin';
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';

  useEffect(() => {
    const results = localChats.filter(chat =>
      (chat.client__first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (chat.client__last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (chat.ai__name?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (chat.platform?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
    );
    setFilteredChats(results);
  }, [searchTerm, localChats]);

  const handleChatClick = (chatId) => {
    setSelectedChatId(chatId);
    setIsDialogOpen(true);
    onChatClick(chatId);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedChatId(null);
  };

  const handleHumanControlToggle = async (event, chatId) => {
    event.stopPropagation();
    const newValue = event.target.checked;
    
    try {
      const token = localStorage.getItem('token');
      let API_BASE_URL = "https://stage-admin.aitomotivelab.com";
      let apiUrl = `${API_BASE_URL}/ai_app/chat-histories/${chatId}/set_human_control/`;
      
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          human_control: newValue
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const updatedChat = await response.json();
  
      setLocalChats(prevChats =>
        prevChats.map(chat =>
          chat.id === chatId ? { ...chat, ...updatedChat } : chat
        )
      );
    } catch (error) {
      console.error('Error setting human control:', error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12} md={4} lg={3} sx={{ height: '100%', overflow: 'auto' }}>
          <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom sx={{ mb: 3, fontWeight: 'bold' }}>
              Chat History
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search chats..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ mb: 3 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <List sx={{ flexGrow: 1, overflow: 'auto' }}>
              {filteredChats.map((chat) => (
                <Fade in={true} key={chat.id}>
                  <ListItem 
                    alignItems="flex-start" 
                    onClick={() => handleChatClick(chat.id)}
                    sx={{ 
                      cursor: 'pointer', 
                      '&:hover': { backgroundColor: 'action.hover' },
                      borderRadius: 2,
                      mb: 2,
                      boxShadow: 1,
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'primary.main' }}>
                        <MessageCircle />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography component="span" variant="subtitle1" color="text.primary" sx={{ fontWeight: 'bold' }}>
                            {chat.client__first_name} {chat.client__last_name}
                          </Typography>
                          <Typography component="span" variant="body2" color="text.secondary" sx={{ ml: 2 }}>
                            {new Date(chat.updated_at).toLocaleString()}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Box mt={1} display="flex" alignItems="center" flexWrap="wrap">
                            <Chip size="small" icon={<User size={14} />} label={chat.ai__name} sx={{ mr: 1, mb: 1 }} />
                            <Chip size="small" icon={<Calendar size={14} />} label={chat.platform} sx={{ mr: 1, mb: 1 }} />
                            {chat.whatsapp && (
                              <Chip size="small" label="WhatsApp" color="primary" sx={{ mr: 1, mb: 1 }} />
                            )}
                            {chat.label_segmentation && (
                            <Chip size="small" icon={<TagIcon size={14} />} 
                              label={chat.label_segmentation_names} sx={{ mr: 1, mb: 1 }} />

                            )}
                            {chat.whatsapp && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    size="small"
                                    checked={chat.human_control}
                                    onChange={(e) => handleHumanControlToggle(e, chat.id)}
                                    onClick={(e) => e.stopPropagation()}
                                    color="primary"
                                  />
                                }
                                label="Human Control"
                                onClick={(e) => e.stopPropagation()}
                              />
                            )}
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </Fade>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9} sx={{ height: '100%', overflow: 'auto' }}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            {selectedChatId ? (
              <ChatHistoryDetail 
                chatId={selectedChatId} 
                whatsapp={localChats.find(chat => chat.id === selectedChatId)?.whatsapp}
              />
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="text.secondary">
                  Select a chat to view details
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      {isMobile && (
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
          fullScreen
        >
          <DialogTitle>
            Chat Details
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {selectedChatId && (
              <ChatHistoryDetail 
                chatId={selectedChatId} 
                whatsapp={localChats.find(chat => chat.id === selectedChatId)?.whatsapp}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default Chats;