import React, { useState, useEffect } from 'react';
import { 
  Card, CardContent, Typography, Button, Grid, CircularProgress, Alert,
  Select, MenuItem, FormControl, InputLabel, Box, Chip, TextField
} from '@mui/material';
import { Add, Delete, Edit, Save, Cancel } from '@mui/icons-material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://stage-admin.aitomotivelab.com';

const BrandPriorityManager = ({ availableBrands }) => {
  const [brands, setBrands] = useState([]);
  const [newBrand, setNewBrand] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editingPriority, setEditingPriority] = useState('');
  const [editingBrand, setEditingBrand] = useState('');

  useEffect(() => {
    fetchBrands();
  }, []);

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
  };

  const fetchBrands = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/car_stock/api/brand-priorities/`, {
        headers: getAuthHeaders(),
      });
      if (!response.ok) throw new Error('Failed to fetch brands');
      const data = await response.json();
      setBrands(data);
    } catch (error) {
      setError(`Error fetching brands: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleAddBrand = async () => {
    if (!newBrand.trim()) {
      setError('Brand name cannot be empty');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/car_stock/api/brand-priorities/`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify({ brand: newBrand.trim(), priority: 100 }),
      });
      if (!response.ok) throw new Error('Failed to add brand');
      fetchBrands();
      setNewBrand('');
    } catch (error) {
      setError(`Error adding brand: ${error.message}`);
    }
  };

  const handleDeleteBrand = async (id) => {
    if (window.confirm('Are you sure you want to delete this brand?')) {
      try {
        const response = await fetch(`${API_BASE_URL}/car_stock/api/brand-priorities/${id}/`, {
          method: 'DELETE',
          headers: getAuthHeaders(),
        });
        if (!response.ok) throw new Error('Failed to delete brand');
        fetchBrands();
      } catch (error) {
        setError(`Error deleting brand: ${error.message}`);
      }
    }
  };

  const handleEditBrand = (id, brand, priority) => {
    setEditingId(id);
    setEditingBrand(brand);
    setEditingPriority(priority.toString());
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditingBrand('');
    setEditingPriority('');
  };

  const handleUpdateBrand = async (id) => {
    if (editingPriority !== null && !isNaN(editingPriority) && editingPriority.trim() !== '') {
      try {
        const response = await fetch(`${API_BASE_URL}/car_stock/api/brand-priorities/${id}/`, {
          method: 'PUT',
          headers: getAuthHeaders(),
          body: JSON.stringify({ 
            brand: editingBrand,
            priority: parseInt(editingPriority, 10) 
          }),
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Failed to update brand priority: ${JSON.stringify(errorData)}`);
        }
        fetchBrands();
        setEditingId(null);
        setEditingBrand('');
        setEditingPriority('');
      } catch (error) {
        setError(`Error updating brand priority: ${error.message}`);
      }
    }
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="200px">
      <CircularProgress />
    </Box>
  );
  if (error) return <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>;

  return (
    <Card elevation={3}>
      <CardContent>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Manage Brand Priorities
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Select a brand</InputLabel>
          <Select
            value={newBrand}
            onChange={(e) => setNewBrand(e.target.value)}
            label="Select a brand"
          >
            {availableBrands.map((brand) => (
              <MenuItem key={brand} value={brand}>{brand}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleAddBrand}
          fullWidth
          sx={{ mt: 2 }}
          startIcon={<Add />}
        >
          Add Brand
        </Button>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {brands.map((brand) => (
            <Grid item xs={12} sm={6} md={4} key={brand.id}>
              <Card elevation={2} sx={{
                transition: '0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                },
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>{brand.brand}</Typography>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Typography variant="body2" mr={1}>Priority:</Typography>
                    {editingId === brand.id ? (
                      <TextField
                        value={editingPriority}
                        onChange={(e) => setEditingPriority(e.target.value)}
                        type="number"
                        size="small"
                        sx={{ width: '80px' }}
                      />
                    ) : (
                      <Chip label={brand.priority} color="primary" size="small" />
                    )}
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteBrand(brand.id)}
                      startIcon={<Delete />}
                      size="small"
                    >
                      Delete
                    </Button>
                    {editingId === brand.id ? (
                      <>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleUpdateBrand(brand.id)}
                          startIcon={<Save />}
                          size="small"
                        >
                          Save
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={handleCancelEdit}
                          startIcon={<Cancel />}
                          size="small"
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleEditBrand(brand.id, brand.brand, brand.priority)}
                        startIcon={<Edit />}
                        size="small"
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BrandPriorityManager;