import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, CssBaseline, Grid, ThemeProvider, Grow, Fade, Card, CardContent } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { useAuth } from '../components/contexts/AuthContext';
import Analytics from '../components/analytics/Analytics';

const tema = createTheme({
  palette: {
    primary: {
      main: '#4361EE',
    },
    secondary: {
      main: '#4895EF',
    },
    background: {
      default: '#F0F2F5',
      paper: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    h2: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 600,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.05)',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 12px 30px 0 rgba(0,0,0,0.1)',
          },
        },
      },
    },
  },
});

const Dashboard = () => {
  const [controlloAnimazione, setControlloAnimazione] = useState(false);
  const { user } = useAuth();
  const NomeUtente = `${user.first_name} ${user.last_name}`;

  useEffect(() => {
    setControlloAnimazione(true);
  }, []);

  const datiGraficoMock = [
    { mese: 'Gen', valore: 4000 },
    { mese: 'Feb', valore: 3000 },
    { mese: 'Mar', valore: 5000 },
    { mese: 'Apr', valore: 4500 },
    { mese: 'Mag', valore: 6000 },
    { mese: 'Giu', valore: 5500 },
  ];

  return (
    <ThemeProvider theme={tema}>
      <CssBaseline />
      <Box sx={{ minHeight: '100vh', backgroundColor: tema.palette.background.default, overflow: 'hidden' }}>
        <Container maxWidth="xl" sx={{ py: { xs: 4, md: 8 } }}>
          <Fade in={controlloAnimazione} timeout={1000}>
            <Box>
              <Typography variant="h2" sx={{ mb: 2, color: tema.palette.primary.main }}>
                Bentornato, <span style={{ color: tema.palette.secondary.main }}>{NomeUtente}</span>
              </Typography>
              <Typography variant="h6" sx={{ mb: 6, color: 'text.secondary' }}>
                Ecco la tua dashboard AItomotivelab per {user.companies[0].name}
              </Typography>
            </Box>
          </Fade>

          

          <Box sx={{ mt: 6 }}>
            <Grow in={controlloAnimazione} timeout={2000}>
              <div>
                <Analytics companyId={user.companies[0].id} />
              </div>
            </Grow>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;