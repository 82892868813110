import React, { useState, useEffect, useMemo, useCallback  } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Chip, Grid, Typography, Paper, Button, MenuItem, TextField, Autocomplete } from '@mui/material';
import { Add as AddIcon, Event as EventIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';  // Import the Auth Context

import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import ServiceDialog from './ServiceDialog';
import BookingDialog from './BookingDialog';
import BookingDetailsDialog from './BookingDetailsDialog';
import SnackbarComponent from './SnackbarComponent';
import CalendarComponent from './CalendarComponent';

const localizer = momentLocalizer(moment);

const BOOKING_API_BASE_URL = 'https://stage-admin.aitomotivelab.com/booking';
const CRM_API_BASE_URL = 'https://stage-admin.aitomotivelab.com/crm/api/';

const createApi = (baseURL) => {
  const api = axios.create({ baseURL });
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  return api;
};

const bookingApi = createApi(BOOKING_API_BASE_URL);
const crmApi = createApi(CRM_API_BASE_URL);

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[5],
}));

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: theme.shape.borderRadius * 2,
}));

const CalendarPage = () => {
  const navigate = useNavigate();
  const { userRole, appAccess } = useAuth();  // Access permissions from AuthContext
  const permissions = appAccess['booking'] || {};  // Extract booking-related permissions
  const canView = permissions.can_view || userRole === 'superadmin';  // Allow superadmins unrestricted access
  const canCreate = permissions.can_create || userRole === 'superadmin';
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';

  const booking_permissions = appAccess['booking_settings'] || {};  // Extract booking-related permissions
  const setting_canView = permissions.can_view || userRole === 'superadmin';  // Allow superadmins unrestricted access
  const setting_canCreate = permissions.can_create || userRole === 'superadmin';
  const setting_canEdit = permissions.can_edit || userRole === 'superadmin';
  const setting_canDelete = permissions.can_delete || userRole === 'superadmin';

  const [events, setEvents] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [serviceDialogOpen, setServiceDialogOpen] = useState(false);
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().add(30, 'days').format('YYYY-MM-DD'));
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [bookingDetailsDialogOpen, setBookingDetailsDialogOpen] = useState(false);
  const [calendarView, setCalendarView] = useState('month');

  // New States for Filtering
  const [selectedStatus, setSelectedStatus] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [bookingClients, setBookingClients] = useState([]);

  useEffect(() => {
    if (canView) {
      fetchServices();
      fetchEvents();
    }
  }, [canView]);

  useEffect(() => {
    filterEvents();
  }, [events, selectedStatus, selectedClient]);

  const fetchServices = async () => {
    try {
      const response = await bookingApi.get('/services/');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
      setSnackbar({ open: true, message: 'Error fetching services', severity: 'error' });
    }
  };

  const fetchClients = useCallback(async (query) => {
    try {
      const response = await crmApi.get('/Clients/', {
        params: { search: query }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching clients:', error);
      setSnackbar({ open: true, message: 'Error fetching clients', severity: 'error' });
      return [];
    }
  }, []);

  const fetchEvents = async () => {
    try {
      const [bookingsResponse, holidaysResponse] = await Promise.all([
        bookingApi.get('/bookings/'),
        bookingApi.get('/holidays/'),
      ]);

      const bookingEvents = formatBookingEvents(bookingsResponse.data);
      const holidayEvents = formatHolidayEvents(holidaysResponse.data);

      setEvents([...bookingEvents, ...holidayEvents]);

      const uniqueClients = Array.from(new Set(bookingsResponse.data.map((b) => b.client_id)))
        .map((client_id) => {
          const clientBooking = bookingsResponse.data.find((b) => b.client_id === client_id);
          return { id: client_id, name: `${clientBooking.client_name} ${clientBooking.last_name}` };
        });

      setBookingClients(uniqueClients);
    } catch (error) {
      console.error('Error fetching events:', error);
      setSnackbar({ open: true, message: 'Error fetching calendar data', severity: 'error' });
    }
  };

  const formatBookingEvents = (bookings) => {
    return bookings.map((booking) => ({
      title: `${booking.service_name} - ${booking.client_name}`,
      start: new Date(booking.start_time),
      end: new Date(booking.end_time),
      allDay: false,
      resource: {
        type: 'booking',
        bookingId: booking.id,
        status: booking.status,
        client_name: booking.client_name,
        last_name: booking.last_name,
        whatsapp: booking.whatsapp,
        number: booking.number,
        email: booking.email,
        client: booking.client_id,
        additional_information_answers: booking.additional_information_answers
      },
      color: getBookingColor(booking.status),
    }));
  };

  const formatHolidayEvents = (holidays) => {
    return holidays.map((holiday) => ({
      title: `Holiday: ${holiday.name}`,
      start: new Date(holiday.start_date),
      end: new Date(holiday.end_date),
      allDay: true,
      resource: {
        type: 'holiday',
        holidayId: holiday.id,
      },
      color: '#9E9E9E', // Gray color for holidays
    }));
  };

  const getBookingColor = (status) => {
    switch (status) {
      case 'pending':
        return '#FFA500'; // Orange
      case 'confirmed':
        return '#4CAF50'; // Green
      case 'cancelled':
        return '#F44336'; // Red
      default:
        return '#2196F3'; // Blue
    }
  };

  const filterEvents = () => {
    const filtered = events.filter((event) => {
      const statusMatch = selectedStatus ? event.resource.status === selectedStatus : true;
      const clientMatch = selectedClient ? event.resource.client === selectedClient : true;
      return statusMatch && clientMatch;
    });
    setFilteredEvents(filtered);
  };

  const handleServiceSelect = async (serviceId) => {
    setSelectedService(serviceId);
    try {
      const response = await bookingApi.get(`/services/${serviceId}/availability/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      });
      setAvailableSlots(response.data);
      setServiceDialogOpen(false);
      setBookingDialogOpen(true);
    } catch (error) {
      console.error('Error fetching available slots:', error);
      setSnackbar({ open: true, message: 'Error fetching available slots', severity: 'error' });
    }
  };

  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);
  };

  const handleClientSelect = (clientId) => {
    setSelectedClient(clientId);
  };

  const handleBookingSubmit = async (bookingData) => {
    if (!bookingData.slot || !bookingData.clientId) {
      setSnackbar({
        open: true,
        message: !bookingData.slot ? 'Please select a time slot' : 'Please select a client',
        severity: 'warning',
      });
      return;
    }

    try {
      await bookingApi.post('/bookings/', {
        service: selectedService,
        client: bookingData.clientId,
        start_time: bookingData.slot.start_time,
        end_time: bookingData.slot.end_time,
        car_model: bookingData.carModel,
        additional_information_answers: bookingData.additionalInfo,
        notes: bookingData.notes,
      });

      setSnackbar({ open: true, message: 'Booking added successfully!', severity: 'success' });
      fetchEvents();
      setBookingDialogOpen(false);
      setSelectedService('');
      setSelectedClient(null);
      setSelectedSlot(null);
    } catch (error) {
      console.error('Error adding booking:', error);
      setSnackbar({ open: true, message: 'Error adding booking', severity: 'error' });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: event.color,
        borderRadius: '4px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block',
      },
    };
  };

  const handleEventClick = (event) => {
    if (event.resource.type === 'booking' && canEdit) {
      setSelectedBooking(event);
      setBookingDetailsDialogOpen(true);
    }
  };

  const handleStatusChange = async (newStatus, emailSubject, emailBody, notificationChannel) => {
    try {
      await bookingApi.patch(`/bookings/${selectedBooking.resource.bookingId}/`, {
        status: newStatus,
        channel: notificationChannel,
        email_subject: emailSubject,
        email_body: emailBody
      });
      setSnackbar({ open: true, message: 'Booking status updated and notification sent successfully!', severity: 'success' });
      fetchEvents();
      setBookingDetailsDialogOpen(false);
    } catch (error) {
      console.error('Error updating booking status and sending notification:', error);
      setSnackbar({ open: true, message: 'Error updating booking status and sending notification', severity: 'error' });
    }
  };

  const calendarViews = useMemo(
    () => ({
      month: true,
      week: true,
      day: true,
      agenda: true,
    }),
    []
  );

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <StyledPaper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h4" component="h1" gutterBottom>
              Appointment Calendar
            </Typography>
          </Grid>
          <Grid item>
            {setting_canView && (
              <>
                <ActionButton
                  variant="outlined"
                  startIcon={<SettingsIcon />}
                  onClick={() => navigate('/booking/availability')}
                >
                  Calendar Settings
                </ActionButton>
                <ActionButton
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setServiceDialogOpen(true)}
                >
                  Book Appointment
                </ActionButton>
              </>
            )}
          </Grid>
        </Grid>

        {/* Filter Section */}
        <Box my={2} display="flex" justifyContent="space-between">
          <Chip
            icon={<EventIcon />}
            label={`View: ${calendarView.charAt(0).toUpperCase() + calendarView.slice(1)}`}
            color="primary"
            variant="outlined"
          />

          {/* Status Filter */}
          <TextField
            select
            label="Filter by Status"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            sx={{ marginRight: 2, width: '200px' }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="confirmed">Confirmed</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
          </TextField>

          {/* Client Filter */}
          <Autocomplete
            id="client-search"
            options={bookingClients}
            getOptionLabel={(option) => option.name || ''}
            value={bookingClients.find((client) => client.id === selectedClient) || null}
            onChange={(event, newValue) => {
              setSelectedClient(newValue ? newValue.id : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter by Client"
                variant="outlined"
                sx={{ marginRight: 2, width: '300px' }}
              />
            )}
          />

          {selectedClient && (
            <Chip
              label={`Client: ${bookingClients.find((c) => c.id === selectedClient)?.name || 'Unknown'}`}
              onDelete={() => setSelectedClient(null)}
              sx={{ marginRight: 2 }}
            />
          )}
        </Box>

        <Box sx={{ flexGrow: 1, width: '100%' }}>
          <CalendarComponent
            localizer={localizer}
            events={filteredEvents}
            eventStyleGetter={eventStyleGetter}
            onSelectEvent={handleEventClick}
            views={calendarViews}
            view={calendarView}
            onView={(newView) => setCalendarView(newView)}
          />
        </Box>
      </StyledPaper>

      {canCreate && (
        <ServiceDialog
          open={serviceDialogOpen}
          services={services}
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={(e) => setStartDate(e.target.value)}
          onEndDateChange={(e) => setEndDate(e.target.value)}
          onClose={() => setServiceDialogOpen(false)}
          onServiceSelect={handleServiceSelect}
        />
      )}

      {canCreate && (
        <BookingDialog
          open={bookingDialogOpen}
          availableSlots={availableSlots}
          selectedSlot={selectedSlot}
          onSlotSelect={handleSlotSelect}
          onClose={() => setBookingDialogOpen(false)}
          onSubmit={handleBookingSubmit}
          fetchClients={fetchClients}
        />
      )}

      {canEdit && (
        <BookingDetailsDialog
          open={bookingDetailsDialogOpen}
          selectedBooking={selectedBooking}
          onClose={() => setBookingDetailsDialogOpen(false)}
          onStatusChange={handleStatusChange}
        />
      )}

      <SnackbarComponent
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </Container>
  );
};

export default CalendarPage;
