import React, { useState, useEffect, useRef } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import {
  Container,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Snackbar,
  CircularProgress,
  Chip,
  List,
  ListItem,
  Divider,
  Box,
  Paper,
  Avatar,
  IconButton,
  Tooltip,
  Fade,
  Zoom,
  Slide,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { 
  Edit as EditIcon, 
  Save as SaveIcon, 
  Person as PersonIcon, 
  SmartToy as AIIcon, 
  DirectionsCar as CarIcon, 
  CheckCircle as CheckIcon, 
  Error as ErrorIcon, 
  ThumbUp, 
  ThumbDown, 
  Comment as CommentIcon,
  ExpandMore as ExpandMoreIcon,
  Label as LabelIcon,
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Alert as MuiAlert } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import SendMessageForm from './SendMessageForm';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://stage-admin.aitomotivelab.com';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f5f5f5',
  boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
  borderRadius: 16,
  overflow: 'hidden',
  position: 'relative',
  height: 'calc(100vh - 100px)',
  display: 'flex',
  flexDirection: 'column',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, #FF6B6B, #4ECDC4, #45B7D1)',
  },
}));

const MessageList = styled(List)({
  flexGrow: 1,
  overflowY: 'auto',
  padding: '20px',
});

const FixedBottomBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'scale(1.2) rotate(5deg)',
    backgroundColor: theme.palette.primary.light,
  },
}));

const MessagePaper = styled(Paper)(({ theme, isBot, isWhatsApp }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  backgroundColor: isBot
    ? isWhatsApp
      ? '#DCF8C6'
      : theme.palette.primary.light
    : isWhatsApp
    ? '#E2E2E2'
    : theme.palette.background.paper,
  color: isBot
    ? isWhatsApp
      ? '#000000'
      : theme.palette.primary.contrastText
    : theme.palette.text.primary,
  borderRadius: 16,
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
  },
}));

const SummaryAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: '16px',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: theme.spacing(2, 0),
  },
}));

const SummaryAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2A2F3B' : '#E8F0FE',
  borderRadius: '16px',
  '&.Mui-expanded': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const SummaryAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9FAFB',
  borderBottomLeftRadius: '16px',
  borderBottomRightRadius: '16px',
}));

const ChatHistoryDetail = ({ chatId }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [chatHistory, setChatHistory] = useState([]);
  const [chatSummary, setChatSummary] = useState('');
  const [labelSegmentation, setLabelSegmentation] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: 'success' });
  const [feedbackDialog, setFeedbackDialog] = useState({ open: false, messageId: null });
  const [feedback, setFeedback] = useState({ category: '', comment: '' });
  const lastMessageRef = useRef(null);
  const [unsentMessage, setUnsentMessage] = useState(null);
  const [isWhatsAppChat, setIsWhatsAppChat] = useState(false);
  const [summaryExpanded, setSummaryExpanded] = useState(false);


  const { userRole, appAccess } = useAuth();  // Access permissions from AuthContext
  const permissions = appAccess['ai_app'] || {};  // Extract booking-related permissions
  const canView = permissions.can_view || userRole === 'superadmin';  // Allow superadmins unrestricted access
  const canCreate = permissions.can_create || userRole === 'superadmin';
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';


  useEffect(() => {
    fetchFullChatHistory();
  }, [chatId]);

  useEffect(() => {
    if (chatHistory.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  const fetchFullChatHistory = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/ai_app/chat-histories/${chatId}/full_chat/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setChatHistory(response.data);
      console.log(response)
      
      // Set the chat summary and label segmentation
      setChatSummary(response.data[0]?.chat?.summary || 'No summary available.');
      setLabelSegmentation(response.data[0]?.chat?.label_segmentation || 'No label segmentation available.');
      
      // Check if this is a WhatsApp chat
      setIsWhatsAppChat(response.data.length > 0 && response.data[0].whatsapp);
      
      // Find the last unsent message
      const lastUnsent = [...response.data].reverse().find(message => message.sent === false);
      setUnsentMessage(lastUnsent);
    } catch (err) {
      setError('Error loading chat history');
      setSnackbar({ open: true, message: 'Error loading chat history', type: 'error' });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (message) => {
    setEditingMessage(message);
    setEditing(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.patch(
        `${API_BASE_URL}/ai_app/chat-histories/${editingMessage.id}/`,
        editingMessage,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setEditing(false);
      setEditingMessage(null);
      setSnackbar({ open: true, message: 'Message updated successfully', type: 'success' });
      fetchFullChatHistory();
    } catch (err) {
      setError('Error saving changes');
      setSnackbar({ open: true, message: 'Error saving changes', type: 'error' });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setEditingMessage({ ...editingMessage, [e.target.name]: e.target.value });
  };

  const handleSendMessage = async (message) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_BASE_URL}/ai_app/whatsapp/${chatId}/human_message/`,
        { sender_message: message.text, car_stocks: message.cars },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setChatHistory([...chatHistory, response.data]);
      setUnsentMessage(null); // Clear the unsent message after successful send
      setSnackbar({ open: true, message: 'Message sent successfully', type: 'success' });
    } catch (err) {
      setError('Error sending message');
      setSnackbar({ open: true, message: 'Error sending message', type: 'error' });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFeedback = (messageId, type) => {
    if (type === 'comment') {
      setFeedbackDialog({ open: true, messageId });
    } else {
      console.log(`Feedback for message ${messageId}: ${type}`);
      setSnackbar({ open: true, message: `${type.charAt(0).toUpperCase() + type.slice(1)} feedback submitted`, type: 'success' });
    }
  };

  const handleFeedbackSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_BASE_URL}/ai_app/chat-histories/${feedbackDialog.messageId}/feedback/`,
        feedback,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSnackbar({ open: true, message: 'Feedback submitted successfully', type: 'success' });
      setFeedbackDialog({ open: false, messageId: null });
      setFeedback({ category: '', comment: '' });
    } catch (err) {
      setSnackbar({ open: true, message: 'Error submitting feedback', type: 'error' });
      console.error(err);
    }
  };

  const MarkdownContent = ({ content }) => (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props }) => <Typography variant="h4" gutterBottom {...props} />,
        h2: ({ node, ...props }) => <Typography variant="h5" gutterBottom {...props} />,
        h3: ({ node, ...props }) => <Typography variant="h6" gutterBottom {...props} />,
        p: ({ node, ...props }) => <Typography variant="body1" paragraph {...props} />,
        a: ({ node, ...props }) => <a style={{ color: theme.palette.primary.main }} {...props} />,
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            <SyntaxHighlighter
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          )
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );

  const CarStockCard = ({ car }) => (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.2 }}
    >
      <Card sx={{ maxWidth: "xl", m: 1, boxShadow: 3, borderRadius: 4, overflow: 'hidden' }}>
        <CardMedia
          component="img"
          height="140"
          image={car.urlmainimage || '/api/placeholder/345/140'}
          alt={`${car.brand} ${car.model}`}
        />
        <CardContent>
          <Typography variant="h6" component="div">
            {car.brand} {car.model}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {car.version}
          </Typography>
          <Typography variant="body2" color="text.primary">
            ID: {car.vehicleid}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CarIcon />}
            sx={{ mt: 2, borderRadius: 20 }}
            onClick={() => console.log(`View details for car ${car.vehicleid}`)}
          >
            View Details
          </Button>
        </CardContent>
      </Card>
    </motion.div>
  );

  const ChatSummaryAccordion = () => (
    <SummaryAccordion expanded={summaryExpanded} onChange={() => setSummaryExpanded(!summaryExpanded)}>
      <SummaryAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="chat-summary-content"
        id="chat-summary-header"
      >
        <Typography variant="h6">Chat Summary</Typography>
      </SummaryAccordionSummary>
      <SummaryAccordionDetails>
        <Typography>{chatSummary}</Typography>
      </SummaryAccordionDetails>
    </SummaryAccordion>
  );

  const LabelSegmentationAccordion = () => (
    <SummaryAccordion>
      <SummaryAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="label-segmentation-content"
        id="label-segmentation-header"
      >
        <Typography variant="h6">
          <LabelIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
          Label Segmentation
        </Typography>
      </SummaryAccordionSummary>
      <SummaryAccordionDetails>
        <Typography>{labelSegmentation}</Typography>
      </SummaryAccordionDetails>
    </SummaryAccordion>
  );

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress size={60} thickness={4} />
    </Box>
  );
  if (error) return <Typography color="error" variant="h5" align="center">{error}</Typography>;
  if (chatHistory.length === 0) return <Typography variant="h5" align="center">No chat history found</Typography>;

  return (
    <Container maxWidth={isSmallScreen ? 'sm' : 'lg'}>
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, textAlign: 'center', mb: 4 }}>
          Complete Conversation
        </Typography>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 4 }}>
          <ChatSummaryAccordion />
        </Box>

        <MessageList>
          <AnimatePresence>
            {chatHistory.map((message, index) => (
              <motion.div
                key={message.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                ref={index === chatHistory.length - 1 ? lastMessageRef : null}
              >
                <ListItem alignItems="flex-start" sx={{ flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                    <Zoom in timeout={500}>
                      <Avatar
                        sx={{ 
                          bgcolor: message.sender_message ? theme.palette.secondary.main : theme.palette.primary.main, 
                          mr: 2,
                          width: 48,
                          height: 48,
                          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'scale(1.1)',
                          },
                        }}
                      >
                        {message.sender_message ? <PersonIcon /> : <AIIcon />}
                      </Avatar>
                    </Zoom>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2" sx={{ color: theme.palette.text.secondary }}>
                        {new Date(message.created_at).toLocaleString()}
                      </Typography>
                      {message.sender_message && (
                        <Fade in timeout={700}>
                          <MessagePaper elevation={1} isBot={false} isWhatsApp={message.whatsapp}>
                            {editing && editingMessage?.id === message.id ? (
                              <TextField
                                fullWidth
                                name="sender_message"
                                value={editingMessage.sender_message || ''}
                                onChange={handleChange}
                                multiline
                                rows={4}
                                variant="outlined"
                                margin="normal"
                              />
                            ) : (
                              <MarkdownContent content={message.sender_message} />
                            )}
                          </MessagePaper>
                        </Fade>
                      )}
                      {message.bot_message && (
                        <Slide in direction="right" timeout={500}>
                          <MessagePaper elevation={1} isBot={true} isWhatsApp={message.whatsapp}>
                            {editing && editingMessage?.id === message.id ? (
                              <TextField
                                fullWidth
                                name="bot_message"
                                value={editingMessage.bot_message || ''}
                                onChange={handleChange}
                                multiline
                                rows={4}
                                variant="outlined"
                                margin="normal"
                                sx={{ backgroundColor: theme.palette.background.paper, borderRadius: 1 }}
                              />
                            ) : (
                              <Box>
                                <MarkdownContent content={message.bot_message} />
                              </Box>
                            )}
                          </MessagePaper>
                        </Slide>
                      )}
                      {message.car_stocks && message.car_stocks.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                          <Typography variant="h6" gutterBottom>Available Cars:</Typography>
                          <Grid container spacing={2}>
                            {message.car_stocks.map((car) => (
                              <Grid item xs={12} sm={6} md={4} key={car.vehicleid}>
                                <CarStockCard car={car} />
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      )}
                    </Box>
                    <Box>
                        {!editing && canEdit && (
                          <Tooltip title="Edit">
                            <AnimatedIconButton onClick={() => handleEdit(message)} size="small">
                              <EditIcon />
                            </AnimatedIconButton>
                          </Tooltip>
                        )}
                        {editing && editingMessage?.id === message.id && canEdit && (
                          <Tooltip title="Save">
                            <AnimatedIconButton onClick={handleSave} size="small" color="primary">
                              <SaveIcon />
                            </AnimatedIconButton>
                          </Tooltip>
                        )}
                      </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    {message.category && (
                      <Chip
                        label={`Category: ${message.category}`}
                        size="small"
                        sx={{ 
                          alignSelf: 'flex-start', 
                          mb: 1, 
                          borderRadius: 16,
                          background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                          color: 'white',
                          fontWeight: 'bold',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                          },
                        }}
                      />
                    )}
                    {canEdit && (
                    <Box>
                      <Tooltip title="Helpful">
                        <IconButton size="small" onClick={() => handleFeedback(message.id, 'helpful')}>
                          <ThumbUp fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Not Helpful">
                        <IconButton size="small" onClick={() => handleFeedback(message.id, 'not_helpful')}>
                          <ThumbDown fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Add Comment">
                        <IconButton size="small" onClick={() => handleFeedback(message.id, 'comment')}>
                          <CommentIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    )}
                  </Box>
                </ListItem>
                {index < chatHistory.length - 1 && (
                  <Divider
                    variant="fullWidth"
                    sx={{
                      my: 2,
                      backgroundImage: 'linear-gradient(to right, transparent, rgba(0,0,0,0.1), transparent)',
                    }}
                  />
                )}
              </motion.div>
            ))}
          </AnimatePresence>
        </MessageList>
        {isWhatsAppChat && canEdit &&(
          <FixedBottomBox>
            <SendMessageForm 
              onSendMessage={handleSendMessage}
              unsentMessage={unsentMessage}
            />
          </FixedBottomBox>
        )}
      </StyledPaper>
      <Dialog open={feedbackDialog.open} onClose={() => setFeedbackDialog({ open: false, messageId: null })}>
        <DialogTitle>Provide Feedback</DialogTitle>
        <DialogContent>
          <Select
            value={feedback.category}
            onChange={(e) => setFeedback({ ...feedback, category: e.target.value })}
            fullWidth
            displayEmpty
            sx={{ mt: 2, mb: 2 }}
          >
            <MenuItem value="" disabled>Select a category</MenuItem>
            <MenuItem value="helpful">Helpful</MenuItem>
            <MenuItem value="not_helpful">Not Helpful</MenuItem>
            <MenuItem value="inaccurate">Inaccurate</MenuItem>
            <MenuItem value="offensive">Offensive</MenuItem>
          </Select>
          <TextField
            multiline
            rows={4}
            value={feedback.comment}
            onChange={(e) => setFeedback({ ...feedback, comment: e.target.value })}
            fullWidth
            placeholder="Add your comment here..."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFeedbackDialog({ open: false, messageId: null })}>Cancel</Button>
          <Button onClick={handleFeedbackSubmit} variant="contained" color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.type}
          sx={{ width: '100%' }}
          icon={snackbar.type === 'success' ? <CheckIcon /> : <ErrorIcon />}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ChatHistoryDetail;