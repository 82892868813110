import React from 'react';
import { 
    Grid, 
    Typography, 
    TextField,
    Tooltip,
    IconButton
} from '@mui/material';
import { HelpCircle } from 'lucide-react';

const ChatColorSettings = ({ colors = {}, onColorChange }) => {
    const handleColorChange = (colorKey) => (event) => {
        onColorChange(colorKey, event.target.value);
    };

    const defaultColors = {
        primary_color: '#3a86ff',
        secondary_color: '#ff006e',
        background_color: '#ffffff',
        text_color: '#000000'
    };

    const currentColors = { ...defaultColors, ...colors };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Chat Color Settings
                    <Tooltip title="Customize the colors of your chat widget">
                        <IconButton size="small">
                            <HelpCircle size={18} />
                        </IconButton>
                    </Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Primary Color"
                    type="color"
                    value={currentColors.primary_color}
                    onChange={handleColorChange('primary_color')}
                    InputProps={{ sx: { height: 56 } }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Secondary Color"
                    type="color"
                    value={currentColors.secondary_color}
                    onChange={handleColorChange('secondary_color')}
                    InputProps={{ sx: { height: 56 } }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Background Color"
                    type="color"
                    value={currentColors.background_color}
                    onChange={handleColorChange('background_color')}
                    InputProps={{ sx: { height: 56 } }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Text Color"
                    type="color"
                    value={currentColors.text_color}
                    onChange={handleColorChange('text_color')}
                    InputProps={{ sx: { height: 56 } }}
                />
            </Grid>
        </Grid>
    );
};

export default ChatColorSettings;