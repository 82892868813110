import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CssBaseline, Grid, createTheme, ThemeProvider } from '@mui/material';
import { itIT, itIT as pickersItIT, itIT as coreItIT } from '@mui/material/locale';
import { fetchUsers, fetchCurrentUserRole } from '../api/user';
import UserTable from './UserTable';
import AddEditUserDialog from './AddEditUserDialog';
import ChangePasswordDialog from './ChangePasswordDialog';
import { useAuth } from '../contexts/AuthContext';

const theme = createTheme(
    {
        palette: {
            primary: { main: '#4361EE' },
            secondary: { main: '#4895EF' },
            third: { main: '#4CC9F0' },
            background: { main: 'rgba(163, 174, 208, 0.05)' },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: { boxShadow: 'none' },
                },
            }
        }
    }, 
    itIT, pickersItIT, coreItIT
);

const roleHierarchy = {
    'superadmin': ['admin','staff', 'external_staff', 'client'],
    'admin': ['admin','staff', 'external_staff', 'client'],
    'staff': ['external_staff', 'client'],
    'external_staff': ['client'],
    'client': []
};

const UserManagementPage = () => {
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [availableRoles, setAvailableRoles] = useState([]);
    const [currentUserRole, setCurrentUserRole] = useState('');
    const [currentUserId, setCurrentUserId] = useState(null);
    const [error, setError] = useState('');
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

    const { userRole, appAccess } = useAuth();
    const permissions = appAccess['user_management'] || {};
    const canView = permissions.can_view || userRole === 'superadmin';
    const canCreate = permissions.can_create || userRole === 'superadmin';
    const canEdit = permissions.can_edit || userRole === 'superadmin';
    const canDelete = permissions.can_delete || userRole === 'superadmin';

    useEffect(() => {
        const fetchData = async () => {
            if (!canView) return;
            try {
                const [usersData, userRole] = await Promise.all([
                    fetchUsers(),
                    fetchCurrentUserRole()
                ]);
                setRows(usersData);
                setCurrentUserRole(userRole.role);
                setCurrentUserId(userRole.id);
                setAvailableRoles(roleHierarchy[userRole.role] || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [canView]);

    const handleAdd = () => {
        if (!canCreate) return;
        setCurrentRow({ id: null, first_name: '', last_name: '', phone: '', email: '', role: '' });
        setOpen(true);
        setError('');
    };

    const handleEdit = (row) => {
        if (!canEdit) return;
        setCurrentRow(row);
        setOpen(true);
        setError('');
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentRow(null);
        setError('');
    };

    const handleChangePassword = () => {
        setPasswordDialogOpen(true);
        setError('');
    };

    const handlePasswordDialogClose = () => {
        setPasswordDialogOpen(false);
        setError('');
    };

    if (!canView) {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.main, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6">
                        Non hai il permesso di visualizzare questa pagina.
                    </Typography>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.main }}>
                <Grid container spacing={2} component="main" sx={{ p: { xs: 4, md: 10 }, mt: { xs: 10, md: 0 } }}>
                    <Box sx={{ mb: { xs: 5, md: 10 } }}>
                        <Typography variant="h2" sx={{ display: { xs: 'none', md: 'block' } }}> Utenti </Typography>
                        <Typography variant="h7" sx={{ pl: 1, display: { xs: 'none', md: 'block' } }}>
                            <b style={{ color: '#2BD900' }}>{rows.length}</b> Utenti totali
                        </Typography>
                    </Box>

                    <Grid container>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 5}}>
                            <Box>
                                {canCreate && (
                                    <Button variant="outlined" color="primary" onClick={handleAdd} sx={{ borderRadius: '20px' }}>
                                        + Aggiungi Utente
                                    </Button>
                                )}
                                <Button variant="contained" color="primary" onClick={handleChangePassword} sx={{ borderRadius: '20px', ml: 2 }}>
                                    Cambia la tua Password
                                </Button>
                            </Box>
                            <UserTable 
                                rows={rows} 
                                setRows={setRows} 
                                handleEdit={handleEdit}
                                canEdit={canEdit}
                                canDelete={canDelete}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <AddEditUserDialog 
                open={open}
                handleClose={handleClose}
                currentRow={currentRow}
                setCurrentRow={setCurrentRow}
                availableRoles={availableRoles}
                rows={rows}
                setRows={setRows}
                error={error}
                setError={setError}
            />

            <ChangePasswordDialog 
                open={passwordDialogOpen}
                handleClose={handlePasswordDialogClose}
                currentUserId={currentUserId}
                error={error}
                setError={setError}
            />
        </ThemeProvider>
    );
};

export default UserManagementPage;