import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  CssBaseline, 
  Paper, 
  Grid,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Login from '../components/auth/Login';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    third: {
      main: '#FFFBDB',
    }
  },
});

const LoginPage = () => {
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    
    if (parts.length > 2) {
      // Subdomain exists
      setCompanyName(parts[0].charAt(0).toUpperCase() + parts[0].slice(1));
    } else {
      // No subdomain, use the domain name
      setCompanyName(parts[0].charAt(0).toUpperCase() + parts[0].slice(1));
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container component="main" sx={{ height: {xs: '100vh', md: '100vh'}, backgroundColor: '#FFFBDB', overflow: 'hidden'}}>
        <Grid
          item
          xs={12}
          sm={4}
          md={8}
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: '#FFFBDB',
            height: {xs: '50vh', md: '100vh'},
          }}
        >
          <Grid container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: {xs: 'center', md: 'center'}, height: '100%', position: 'relative', zIndex: 10 }}>
            <Box component="span" sx={{ position: 'absolute', top: {xs: -350, md: -900, xl: -800}, right: {xs: -250, md: -400}, width: {xs: 514, md: 1071}, height: {xs: 514, md: 1071}, overflow: 'hidden' }}>
              <svg viewBox="0 0 514 514" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.09908 229.277C2.87568 212.431 6.31523 195.728 11.4177 179.432L179.432 11.4178C195.727 6.3153 212.431 2.87573 229.277 1.09914L1.09908 229.277ZM80.1726 443.022C87.0467 449.517 94.1918 455.56 101.57 461.151L461.151 101.57C455.56 94.1919 449.517 87.0468 443.022 80.1726L80.1726 443.022ZM153.994 491.814C145.046 487.928 136.275 483.507 127.729 478.552L478.552 127.729C483.507 136.275 487.928 145.046 491.814 153.994L153.994 491.814ZM186.091 503.277C196.804 506.315 207.676 508.643 218.632 510.262L510.262 218.632C508.643 207.676 506.315 196.804 503.277 186.091L186.091 503.277ZM303.242 508.738C288.769 511.414 274.11 512.839 259.44 513.014L513.014 259.44C512.839 274.11 511.414 288.769 508.738 303.242L303.242 508.738ZM367.966 487.574C393.225 475.38 416.9 458.807 437.853 437.853C458.807 416.9 475.38 393.225 487.574 367.967L367.966 487.574ZM420.574 59.0617L59.0616 420.574C52.9673 413.269 47.342 405.706 42.1858 397.923L397.923 42.1859C405.706 47.3421 413.269 52.9674 420.574 59.0617ZM342.517 14.5057C351.946 17.8578 361.219 21.7782 370.282 26.2669L26.2669 370.282C21.7782 361.219 17.8578 351.946 14.5056 342.517L342.517 14.5057ZM4.99258 308.47L308.47 4.99263C296.975 2.62042 285.349 1.04199 273.679 0.257343L0.257297 273.679C1.04195 285.349 2.62038 296.975 4.99258 308.47Z" fill="#FFBE0B"/>
              </svg>
            </Box>
            <Box component="span" sx={{ position: 'absolute', top: {xs: -100, md: -200, lg: '-30%', xl: -120}, left: {xs: -120, md: -230, lg: -230, xl: -100}, width: {xs: 252, md: 502}, height: {xs: 241, md: 462} }}>
              <svg viewBox="0 0 502 462" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.459473 223.787L187.501 0.880005L287.023 274.316L0.459473 223.787Z" fill="#F194B4" fillOpacity="0.5"/>
                <path d="M105.596 317.854L292.637 94.9471L392.16 368.383L105.596 317.854Z" fill="#F194B4" fillOpacity="0.5"/>
                <path d="M214.697 410.68L401.738 187.773L501.261 461.209L214.697 410.68Z" fill="#F194B4" fillOpacity="0.5"/>
              </svg>
            </Box>
            <Box component="span" sx={{ position: 'absolute', bottom: {xs: -175, md: -450, xl: -400}, left: {xs: -75, md: -350, xl: -300}, width: {xs: 261, md: 700}, height: {xs: 264, md: 707} }}>
              <svg viewBox="0 0 700 707" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M700 357C700 550.3 543.3 707 350 707C156.7 707 0 550.3 0 357C0 163.7 156.7 7 350 7C543.3 7 700 163.7 700 357Z" fill="#FF674D"/>
                <path d="M588 62.5C588 97.0178 560.018 125 525.5 125C490.982 125 463 97.0178 463 62.5C463 27.9822 490.982 0 525.5 0C560.018 0 588 27.9822 588 62.5Z" fill="#5C415D"/>
              </svg>
            </Box>
            <Box component="span" sx={{ position: 'absolute', right: {xs: -70, md: -150, xl: -120}, bottom: {xs: -70, md: -150, xl: -120}, width: {xs: 180, md: 403}, height: {xs: 177, md: 397} }}>
              <svg viewBox="0 0 403 397" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M201.5 0L249.761 71.9047L332.95 47.8439L323.701 133.948L402.893 168.989L340.461 229.003L378.602 306.75L292.201 312.593L271.443 396.667L201.5 345.605L131.557 396.667L110.799 312.593L24.3978 306.75L62.5387 229.003L0.106812 168.989L79.2995 133.948L70.0499 47.8439L153.239 71.9047L201.5 0Z" fill="#006C67"/>
              </svg>
            </Box>

            {/* Company name for Desktop */}
            <Typography variant="h2" fontFamily="Poppins" fontWeight='200' sx={{ display: {xs: 'none', md: 'block'}, textAlign: 'center' }}>
              {companyName}
            </Typography>
            {/* Company name for Mobile */}
            <Typography variant="h3" fontFamily="Poppins" fontWeight='200' sx={{ display: {xs: 'block', md: 'none'}, position: 'relative', top: 20  }}>
              {companyName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={4} component={Paper} elevation={2} square sx={{ backgroundColor: '#F4D06F', borderTopLeftRadius: '20px', borderTopRightRadius: { xs: 20, md: 0}, borderBottomLeftRadius: {xs: 0, md: 20}, zIndex: '20'}}>
          <Container sx={{ height: {xs: '50vh', md: '100vh'}, p: 5 }}> 
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            {/* Greeting for Desktop */}
            <Typography component="h1" variant="h2" color="#FFFBDB" sx={{ fontFamily: 'Poppins', fontWeight: '300', textAlign: 'center', display: {xs: 'none', sm: 'none', md: 'block'} }}> 
              Ciao, bentornato!
            </Typography>

            {/* Greeting for Mobile */}
            <Typography component="h1" variant="h4" color="#FFFBDB" sx={{ fontFamily: 'Poppins', fontWeight: '300', textAlign: 'center', display: {xs: 'block', sm: 'none', md: 'none'} }}> 
              Ciao, bentornato!
            </Typography>
            <Login />
          </Box>
          </Container>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default LoginPage;