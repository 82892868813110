// CustomButton.js
import React from 'react';
import { Button } from '@mui/material';

const CustomButton = ({ onClick, icon, color = 'rgb(244, 247, 254)' }) => (
  <Button 
    size="small" 
    variant="contained" 
    onClick={onClick} 
    style={{ borderRadius: '50%', minWidth: 40, height: 40, backgroundColor: color, marginLeft: 16 }}
  >
    {icon}
  </Button>
);

export default CustomButton;