// PaginaRuoli.jsx

import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Box, Grid, Button } from '@mui/material';
import { itIT, itIT as pickersItIT, itIT as coreItIT } from '@mui/material/locale';
import {
  fetchRoles,
  createRole,
  updateRole,
  deleteRole,
  fetchCurrentUserRole,
  fetchCompanies,
  fetchAvailableApps,
  fetchCarBrands,
  fetchCustomerCategories,
  fetchRegistrationFields,
  fetchFilterOptions,
} from '../api/roleManagement';
import Header from './Header';
import DataGridComponent from './DataGridComponent';
import RoleDialogForm from './RoleDialogForm';
import { useAuth } from '../contexts/AuthContext';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#4361EE' },
      secondary: { main: '#4895EF' },
      third: { main: '#4CC9F0' },
      background: { main: 'rgba(163, 174, 208, 0.05)' },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: { boxShadow: 'none' },
        },
      },
    },
  },
  itIT,
  pickersItIT,
  coreItIT
);

const roleHierarchy = {
  superadmin: ['admin', 'staff', 'external_staff', 'client'],
  admin: ['admin', 'staff', 'external_staff', 'client'],
  staff: ['external_staff', 'client'],
  external_staff: ['client'],
  client: [],
};

const PaginaRuoli = () => {
  const [roles, setRoles] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [currentUserRole, setCurrentUserRole] = useState('');
  const [companies, setCompanies] = useState([]);
  const [availableApps, setAvailableApps] = useState([]);
  const [carBrands, setCarBrands] = useState([]);
  const [customerCategories, setCustomerCategories] = useState([]);
  const [registrationFields, setRegistrationFields] = useState([]);
  const [filterTypes, setFilterTypes] = useState([]);
  const [operators, setOperators] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['user_management'] || {};
  const canView = permissions.can_view || userRole === 'superadmin';
  const canCreate = permissions.can_create || userRole === 'superadmin';
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [
          rolesData,
          userRole,
          companiesData,
          appsData,
          brandsData,
          categoriesData,
          fieldsData,
          filterOptionsData,
        ] = await Promise.all([
          fetchRoles(),
          fetchCurrentUserRole(),
          fetchCompanies(),
          fetchAvailableApps(),
          fetchCarBrands(),
          fetchCustomerCategories(),
          fetchRegistrationFields(),
          fetchFilterOptions(),
        ]);
        setRoles(rolesData);
        setCurrentUserRole(userRole.role);
        setAvailableRoles(roleHierarchy[userRole.role] || []);
        setCompanies(companiesData);
        setAvailableApps(appsData);
        setCarBrands(brandsData);
        setCustomerCategories(categoriesData);
        setRegistrationFields(fieldsData);

        setFilterTypes(
          Object.entries(filterOptionsData.filter_types || {}).map(([key, label]) => ({
            value: key,
            label,
          }))
        );
        setOperators(
          Object.entries(filterOptionsData.operators || {}).map(([key, label]) => ({
            value: key,
            label,
          }))
        );
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleAdd = () => {
    setCurrentRole(null);
    setOpen(true);
    setError('');
  };

  const handleEdit = (role) => {
    setCurrentRole(role);
    setOpen(true);
    setError('');
  };

  const handleDelete = async (id) => {
    try {
      await deleteRole(id);
      setRoles(roles.filter((role) => role.id !== id));
    } catch (error) {
      console.error('Error deleting role:', error);
      setError('Error deleting role. Please try again.');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentRole(null);
    setError('');
  };

  const handleSave = async (roleData) => {
    try {
      if (currentRole) {
        const updatedRole = await updateRole(currentRole.id, roleData);
        setRoles(roles.map((role) => (role.id === updatedRole.id ? updatedRole : role)));
      } else {
        const newRole = await createRole(roleData);
        setRoles([...roles, newRole]);
      }
      handleClose();
    } catch (error) {
      console.error('Error saving role:', error);
      setError('Error saving role. Please try again.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.main }}>
        <Grid container spacing={2} component="main" sx={{ p: { xs: 4, md: 10 }, mt: { xs: 10, md: 0 } }}>
          <Header totalRoles={roles.length} />
          <Grid container>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 5 }}>
              {canCreate && (
                <Box>
                  <Button variant="outlined" color="primary" onClick={handleAdd} sx={{ borderRadius: '20px' }}>
                    + Aggiungi Ruolo
                  </Button>
                </Box>
              )}
              <DataGridComponent rows={roles} onEdit={handleEdit} onDelete={handleDelete} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {open && (
        <RoleDialogForm
          open={open}
          onClose={handleClose}
          onSave={handleSave}
          initialData={currentRole}
          companies={companies}
          availableApps={availableApps}
          availableRoles={availableRoles}
          carBrands={carBrands}
          customerCategories={customerCategories}
          registrationFields={registrationFields}
          filterTypes={filterTypes}
          operators={operators}
        />
      )}
    </ThemeProvider>
  );
};

export default PaginaRuoli;
