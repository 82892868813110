import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { 
  Box, Typography, List, ListItem, ListItemText, ListItemAvatar, 
  Avatar, Chip, Dialog, DialogContent, DialogTitle, IconButton, 
  Switch, FormControlLabel, Grid, Paper, Badge, CircularProgress, 
  Alert, Snackbar, Tooltip, Pagination, Fade
} from '@mui/material';
import { MessageCircle, X as CloseIcon, Mail, RefreshCw } from 'lucide-react';
import ChatFiltersSection from './ChatFiltersSection';
import { useAuth } from '../contexts/AuthContext';
import ChatHistoryDetail from './ChatHistoryDetail';

const WSS_BASE_URL = process.env.REACT_APP_WSS_BASE_URL || 'wss://stage-admin.aitomotivelab.com';

const Chats = ({ onChatClick }) => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authError, setAuthError] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(20);
  const [refreshing, setRefreshing] = useState(false);
  const wsRef = useRef(null);
  const reconnectionAttempts = useRef(0);
  const isManuallyClosed = useRef(false);
  const [isPageChanging, setIsPageChanging] = useState(false);


  const [filters, setFilters] = useState({
    search: '',
    is_connected: null,
    human_control: null,
    date_range: [null, null],
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const { token = localStorage.getItem('token') } = useAuth();

  const updateChat = useCallback((updatedChat) => {
    setChats(prevChats => {
      const chatIndex = prevChats.findIndex(chat => Number(chat.id) === Number(updatedChat.id));
      if (chatIndex > -1) {
        const updatedChats = [...prevChats];
        // Merge the existing chat data with the new data
        updatedChats[chatIndex] = { 
          ...updatedChats[chatIndex], 
          ...updatedChat,
          // Update client name if provided in the chat object
          ...(updatedChat.chat?.client_name && {
            client__first_name: updatedChat.chat.client_name.split(' ')[0],
            client__last_name: updatedChat.chat.client_name.split(' ').slice(1).join(' '),
          }),
          // Update other fields if provided
          ...(updatedChat.chat?.unique_id && { unique_id: updatedChat.chat.unique_id }),
          ...(updatedChat.chat?.ai_name && { ai_name: updatedChat.chat.ai_name }),
          ...(updatedChat.chat?.summary && { summary: updatedChat.chat.summary }),
          // Update last message if provided
          ...(updatedChat.bot_message && { last_message: updatedChat.bot_message }),
          ...(updatedChat.sender_message && { last_message: updatedChat.sender_message }),
          // Mark as updated
          updated_at: updatedChat.updated_at || new Date().toISOString(),
          is_new: true
        };
        return updatedChats;
      } else {
        // If it's a new chat, add it to the list
        return [{
          id: updatedChat.id,
          client__first_name: updatedChat.chat?.client_name ? updatedChat.chat.client_name.split(' ')[0] : '',
          client__last_name: updatedChat.chat?.client_name ? updatedChat.chat.client_name.split(' ').slice(1).join(' ') : '',
          unique_id: updatedChat.chat?.unique_id || '',
          ai_name: updatedChat.chat?.ai_name || '',
          summary: updatedChat.chat?.summary || '',
          last_message: updatedChat.bot_message || updatedChat.sender_message || '',
          updated_at: updatedChat.updated_at || new Date().toISOString(),
          is_new: true
        }, ...prevChats];
      }
    });
  }, []);

  const updateChatWithNewMessage = useCallback((messageData) => {
    const chatId = messageData.chat_id;
    setChats(prevChats => {
      const chatIndex = prevChats.findIndex(chat => Number(chat.id) === Number(chatId));
      if (chatIndex > -1) {
        const updatedChats = [...prevChats];
        updatedChats[chatIndex] = { 
          ...updatedChats[chatIndex],
          updated_at: new Date().toISOString(),
          last_message: messageData.bot_message || messageData.sender_message || messageData.admin_message,
          is_new: true
        };
        return updatedChats;
      }
      return prevChats;
    });
  }, []);

  const updateConnectionStatus = useCallback((data) => {
    setChats(prevChats => 
      prevChats.map(chat => 
        Number(chat.id) === Number(data.chat_id)
          ? { 
              ...chat, 
              is_connected: data.is_connected,
              last_connected: data.last_connected,
              last_disconnected: data.last_disconnected
            }
          : chat
      )
    );
  }, []);

  const handleWebSocketMessage = useCallback((data) => {
    console.log('WebSocket message received:', data);
    const action = data.action || data.type;
    switch (action) {
      case 'get_all_chats':
        if (data.error) {
          setError(data.error);
          setChats([]);
          setTotalPages(1);
          setCurrentPage(1);
        } else {
          setChats(data.data || []);
          const pagination = data.pagination || {};
          setTotalPages(pagination.total_pages || 1);
          setCurrentPage(pagination.current_page || 1);
        }
        setLoading(false);
        setRefreshing(false);
        setIsPageChanging(false);
        break;
      case 'set_human_control':
      case 'update_chat':
        console.log("update_chat", data)
        updateChat(data.data);
        break;
      case 'new_message':
      case 'message_complete':
      case 'admin_message':
        updateChatWithNewMessage(data.data);
        break;
      case 'update_connection_status':
        updateConnectionStatus(data.data);
        break;
      case 'chat_joined':
        setChats(prevChats => {
          const newChat = data.data;
          const existingChatIndex = prevChats.findIndex(chat => Number(chat.id) === Number(newChat.id));
          
          if (existingChatIndex > -1) {
            const updatedChats = [...prevChats];
            updatedChats[existingChatIndex] = {
              ...updatedChats[existingChatIndex],
              ...newChat,
            };
            return updatedChats;
          } else {
            return [newChat, ...prevChats];
          }
        });
        break;
      default:
        console.log('Unhandled WebSocket message:', data);
    }
  }, [updateChat, updateChatWithNewMessage, updateConnectionStatus]);

  const connectWebSocket = useCallback(() => {
    if (!token) {
      setError('Authentication token is missing. Please log in again.');
      setLoading(false);
      return;
    }

    if (wsRef.current) {
      wsRef.current.close();
    }

    wsRef.current = new WebSocket(`${WSS_BASE_URL}/ws/admin_chat/?token=${token}`);

    wsRef.current.onopen = () => {
      console.log('WebSocket Connected');
      setError(null);
      setAuthError(false);
      reconnectionAttempts.current = 0;
      fetchChats();
    };

    wsRef.current.onmessage = (event) => {
      let data;
      try {
        data = JSON.parse(event.data);
        console.log('Received data:', data);
      } catch (e) {
        console.error('Failed to parse message', e);
        return;
      }
      if (data.type === 'error' && data.message === 'Authentication required.') {
        setAuthError(true);
        wsRef.current.close();
      } else {
        if (data.type === 'chat.update' && !data.action) {
          data.action = 'update_chat';
        }
        handleWebSocketMessage(data);
      }
    };

    wsRef.current.onerror = (error) => {
      console.error('WebSocket Error:', error);
      setError('Failed to connect to the chat server. Please try again later.');
      setLoading(false);
    };

    wsRef.current.onclose = (event) => {
      console.log('WebSocket Disconnected:', event.code, event.reason);
      if (!authError && !isManuallyClosed.current) {
        reconnectionAttempts.current += 1;
        const delay = Math.min(10000, 1000 * Math.pow(2, reconnectionAttempts.current));
        setTimeout(() => connectWebSocket(), delay);
      }
    };
  }, [token, handleWebSocketMessage]);

  const fetchChats = useCallback(() => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      const filtersToSend = { ...filters };
      
      if (filters.date_range && filters.date_range[0] && filters.date_range[1]) {
        filtersToSend.date_range = [
          filters.date_range[0].toISOString(),
          filters.date_range[1].toISOString(),
        ];
      } else {
        delete filtersToSend.date_range;
      }
      
      if (filters.is_connected === null || filters.is_connected === undefined) {
        delete filtersToSend.is_connected;
      }
      
      if (filters.human_control === null || filters.human_control === undefined) {
        delete filtersToSend.human_control;
      }
  
      wsRef.current.send(JSON.stringify({
        action: 'get_all_chats',
        filters: filtersToSend,
        page: currentPage,
        page_size: pageSize
      }));
    } else {
      console.error('WebSocket is not open. Cannot fetch chats.');
      setError('Unable to connect to the server. Please try again later.');
    }
  }, [filters, currentPage, pageSize]);

  useEffect(() => {
    if (!isPageChanging) {
      fetchChats();
    }
  }, [fetchChats, isPageChanging]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      isManuallyClosed.current = true;
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [connectWebSocket]);

  useEffect(() => {
    fetchChats();
  }, [fetchChats]);

  const handleChatClick = (chatId) => {
    setSelectedChatId(chatId);
    if (onChatClick) {
      onChatClick(chatId);
    }
    // Remove 'is_new' flag when chat is clicked
    setChats(prevChats => 
      prevChats.map(chat => 
        Number(chat.id) === Number(chatId) ? { ...chat, is_new: false } : chat
      )
    );
  };

  const handleHumanControlToggle = useCallback((event, chatId) => {
    event.stopPropagation(); // Prevent chat selection when toggling the switch
    const newValue = event.target.checked;
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({
        action: 'set_human_control',
        pk: chatId,
        human_control: newValue
      }));
    } else {
      setError('Unable to update human control. Please check your connection.');
    }
  }, []);

  const handleCloseError = () => {
    setError(null);
  };

  const handlePageChange = (event, value) => {
    setIsPageChanging(true);
    setCurrentPage(value);
    fetchChats();
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
    setCurrentPage(1);

    fetchChats();
    setIsPageChanging(false);

  };

  const handleRefresh = () => {
    setRefreshing(true);
    setIsPageChanging(false);
    fetchChats();
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden', bgcolor: 'background.default' }}>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12} md={4} lg={3} sx={{ height: '100%', overflow: 'auto' }}>
          <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', borderRadius: 4, bgcolor: 'background.paper' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <Typography variant={isMobile ? "h5" : "h4"} component="h1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                Chat History
              </Typography>
              <Tooltip title="Refresh">
                <IconButton onClick={handleRefresh} disabled={refreshing}>
                  <RefreshCw className={refreshing ? 'spin' : ''} />
                </IconButton>
              </Tooltip>
            </Box>
            <ChatFiltersSection filters={filters} handleFilterChange={handleFilterChange} />
            <List sx={{ flexGrow: 1, overflow: 'auto' }}>
              <LayoutGroup>
                <AnimatePresence>
                  {chats.map((chat) => (
                    <motion.div
                      key={chat.id}
                      layout
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <ListItem 
                        alignItems="flex-start" 
                        onClick={() => handleChatClick(chat.id)}
                        sx={{ 
                          cursor: 'pointer', 
                          borderRadius: 2,
                          mb: 2,
                          boxShadow: 1,
                          transition: 'all 0.3s ease-in-out',
                          '&:hover': {
                            transform: 'scale(1.02)',
                            boxShadow: 3,
                            bgcolor: 'action.hover',
                          },
                          position: 'relative',
                          bgcolor: chat.is_new ? 'action.selected' : 'background.paper',
                        }}
                      >
                        <ListItemAvatar>
                          <Tooltip title={chat.is_connected ? 'Connected' : 'Disconnected'} arrow>
                            <Badge
                              overlap="circular"
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                              variant="dot"
                              sx={{
                                '& .MuiBadge-badge': {
                                  backgroundColor: chat.is_connected ? 'success.main' : 'error.main',
                                  width: 10,
                                  height: 10,
                                  borderRadius: '50%',
                                },
                              }}
                            >
                              <Avatar sx={{ bgcolor: 'primary.main' }}>
                                <MessageCircle />
                              </Avatar>
                            </Badge>
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="span" variant="subtitle1" color="text.primary" sx={{ fontWeight: 'bold' }}>
                                {chat.client__first_name} {chat.client__last_name}
                              </Typography>
                              <Typography component="span" variant="body2" color="text.secondary" sx={{ ml: 2 }}>
                                {new Date(chat.updated_at).toLocaleString()}
                                </Typography>
                              {chat.is_new && (
                                <Chip 
                                  label="New" 
                                  color="secondary" 
                                  size="small" 
                                  sx={{ 
                                    ml: 2,
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                  }} 
                                />
                              )}
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography variant="body2" color="text.secondary" noWrap>
                                {chat.last_message}
                              </Typography>
                              <Box mt={1} display="flex" alignItems="center" flexWrap="wrap">
                                <Tooltip title="Email" arrow>
                                  <Chip size="small" icon={<Mail size={14} />} label={chat.client__email} sx={{ mr: 1, mb: 1 }} />
                                </Tooltip>
                                <Tooltip title={chat.human_control ? 'Human Control Active' : 'AI Control Active'} arrow>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        size="small"
                                        checked={chat.human_control}
                                        onChange={(event) => handleHumanControlToggle(event, chat.id)}
                                        color="primary"
                                      />
                                    }
                                    label="Human Control"
                                  />
                                </Tooltip>
                              </Box>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </LayoutGroup>
            </List>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size={isMobile ? "small" : "medium"}
              disabled={loading || refreshing}
            />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9} sx={{ height: '100%', overflow: 'auto', display: { xs: 'none', md: 'block' } }}>
          <Paper elevation={3} sx={{ p: 2, height: '100%', borderRadius: 4, bgcolor: 'background.paper' }}>
            {selectedChatId ? (
              <ChatHistoryDetail 
                chatId={selectedChatId} 
                whatsapp={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.whatsapp}
                humanControl={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.human_control}
                isConnected={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.is_connected}
              />
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="text.secondary">
                  Select a chat to view details
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      {isMobile && (
        <Dialog
          open={!!selectedChatId}
          onClose={() => setSelectedChatId(null)}
          fullScreen
          TransitionComponent={Fade}
        >
          <DialogTitle>
            Chat Details
            <IconButton
              aria-label="close"
              onClick={() => setSelectedChatId(null)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {selectedChatId && (
              <ChatHistoryDetail 
                chatId={selectedChatId} 
                whatsapp={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.whatsapp}
                humanControl={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.human_control}
                isConnected={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.is_connected}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default Chats;