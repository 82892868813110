import React, { useState, useEffect, useCallback } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, Grid, Select, MenuItem,
    Typography, Collapse, IconButton, Box, Slider,
    Divider, Paper, Tooltip, Snackbar, Switch, FormControlLabel
} from '@mui/material';
import { X, ChevronDown, ChevronUp, HelpCircle, Bot, Zap, Shield, MessageCircle, Thermometer, Copy } from 'lucide-react';
import { fetchAI, createOrUpdateAI } from '../api/ai';
import ChatColorSettings from './ChatColorSettings';

const defaultChatSettings = {
    primary_color: '#3a86ff',
    secondary_color: '#ff006e',
    background_color: '#ffffff',
    text_color: '#000000'
};

const AIManagement = ({ open, onClose, companyId, showSnackbar }) => {
    const [currentAI, setCurrentAI] = useState({
        name: '',
        comportamento: '',
        obbiettivo: '',
        limiti: '',
        telegram_key: '',
        numero_whatsapp: '',
        k_retrieve: 3,
        k_retrieve_messages: 2,
        temperature: 0.0,
        model: 'gpt-4o',
        chat_settings: defaultChatSettings,
        attiva: true
    });
    const [isLoading, setIsLoading] = useState(true);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [scriptVisible, setScriptVisible] = useState(false);
    const [script, setScript] = useState('');

    useEffect(() => {
        if (open && companyId) {
            fetchCompanyAI();
        }
    }, [open, companyId]);

    const fetchCompanyAI = useCallback(async () => {
        setIsLoading(true);
        try {
            const ai = await fetchAI(companyId);
            if (ai) {
                setCurrentAI({
                    ...ai,
                    chat_settings: { ...defaultChatSettings, ...ai.chat_settings },
                    attiva: ai.attiva !== undefined ? ai.attiva : true
                });
            } else {
                setCurrentAI(prevState => ({
                    ...prevState,
                    chat_settings: defaultChatSettings,
                    attiva: true
                }));
            }
        } catch (error) {
            console.error('Error fetching AI:', error);
            showSnackbar('Error fetching AI', 'error');
        } finally {
            setIsLoading(false);
        }
    }, [companyId, showSnackbar]);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setCurrentAI(prevAI => ({ ...prevAI, [name]: value }));
    }, []);

    const handleSwitchChange = useCallback((e) => {
        const { name, checked } = e.target;
        setCurrentAI(prevAI => ({ ...prevAI, [name]: checked }));
    }, []);

    const handleSliderChange = useCallback((name) => (_, newValue) => {
        setCurrentAI(prevAI => ({ ...prevAI, [name]: newValue }));
    }, []);

    const handleColorChange = useCallback((colorKey, value) => {
        setCurrentAI(prevAI => ({
            ...prevAI,
            chat_settings: {
                ...prevAI.chat_settings,
                [colorKey]: value
            }
        }));
    }, []);

    const generateScript = useCallback((aiToken) => {
        const scriptContent = `
            <script src="https://yourserver.com/widget-script/"></script>
            <script>
                (function() {
                    var initWidget = function() {
                        if (window.mw && typeof window.mw.init === 'function') {
                            window.mw.init('${aiToken}');
                        } else {
                            setTimeout(initWidget, 100);
                        }
                    };
                    initWidget();
                })();
            </script>
        `;
        setScript(scriptContent);
        setScriptVisible(true);
    }, []);

    const handleSaveAI = useCallback(async () => {
        try {
            const aiDataToSave = {
                ...currentAI,
                chat_settings: currentAI.chat_settings || defaultChatSettings
            };
            const savedAI = await createOrUpdateAI(companyId, aiDataToSave);
            showSnackbar('AI saved successfully', 'success');
            generateScript(savedAI.api_token);
        } catch (error) {
            console.error('Error saving AI:', error);
            showSnackbar('Error saving AI', 'error');
        }
    }, [currentAI, companyId, showSnackbar, generateScript]);

    const handleCopyScript = useCallback(() => {
        navigator.clipboard.writeText(script).then(() => {
            showSnackbar('Script copied to clipboard', 'success');
        }, (err) => {
            console.error('Could not copy text: ', err);
            showSnackbar('Failed to copy script', 'error');
        });
    }, [script, showSnackbar]);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Bot size={24} style={{ marginRight: 8 }} />
                    {currentAI.id ? 'Edit AI' : 'Create AI'}
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="name"
                            label="AI Name"
                            value={currentAI.name}
                            onChange={handleInputChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={currentAI.attiva}
                                    onChange={handleSwitchChange}
                                    name="attiva"
                                    color="primary"
                                />
                            }
                            label="Active"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="comportamento"
                            label="Behavior"
                            value={currentAI.comportamento}
                            onChange={handleInputChange}
                            multiline
                            rows={3}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="obbiettivo"
                            label="Objective"
                            value={currentAI.obbiettivo}
                            onChange={handleInputChange}
                            multiline
                            rows={3}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="limiti"
                            label="Limitations"
                            value={currentAI.limiti}
                            onChange={handleInputChange}
                            multiline
                            rows={3}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                        <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
                            <Typography variant="h6">Advanced Options</Typography>
                            <IconButton onClick={() => setShowAdvanced(!showAdvanced)}>
                                {showAdvanced ? <ChevronUp /> : <ChevronDown />}
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Collapse in={showAdvanced}>
                    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="telegram_key"
                                    label="Telegram Key"
                                    value={currentAI.telegram_key}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="numero_whatsapp"
                                    label="WhatsApp Number"
                                    value={currentAI.numero_whatsapp}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography gutterBottom>K Retrieve</Typography>
                                <Slider
                                    value={currentAI.k_retrieve}
                                    onChange={handleSliderChange('k_retrieve')}
                                    step={1}
                                    marks
                                    min={1}
                                    max={8}
                                    valueLabelDisplay="auto"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography gutterBottom>K Retrieve Messages</Typography>
                                <Slider
                                    value={currentAI.k_retrieve_messages}
                                    onChange={handleSliderChange('k_retrieve_messages')}
                                    step={1}
                                    marks
                                    min={1}
                                    max={8}
                                    valueLabelDisplay="auto"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography gutterBottom>Temperature</Typography>
                                <Slider
                                    value={currentAI.temperature}
                                    onChange={handleSliderChange('temperature')}
                                    step={0.1}
                                    marks
                                    min={0}
                                    max={1}
                                    valueLabelDisplay="auto"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    fullWidth
                                    name="model"
                                    value={currentAI.model}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                >
                                    <MenuItem value="gpt-3.5-turbo">GPT-3.5 Turbo</MenuItem>
                                    <MenuItem value="gpt-4-turbo-preview">GPT-4 Turbo Preview</MenuItem>
                                    <MenuItem value="gpt-4o">GPT-4O</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ my: 2 }} />
                                <ChatColorSettings 
                                    colors={currentAI.chat_settings}
                                    onColorChange={handleColorChange}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Collapse>
                
                {scriptVisible && (
                    <Box mt={3}>
                        <Typography variant="h6" gutterBottom>
                            Website Integration Script
                        </Typography>
                        <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
                            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                                {script}
                            </pre>
                            <IconButton
                                onClick={handleCopyScript}
                                sx={{ position: 'absolute', top: 8, right: 8 }}
                            >
                                <Copy />
                            </IconButton>
                        </Paper>
                        <Typography variant="body2" color="textSecondary" mt={1}>
                            Add this script to your website to integrate the chat functionality.
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} startIcon={<X />}>
                    Cancel
                </Button>
                <Button onClick={handleSaveAI} variant="contained" color="primary" startIcon={<Zap />}>
                    Save AI
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AIManagement;