import React, { useState, useEffect } from 'react';
import { 
  TextField, Box, Typography, Grid, Dialog,
  DialogTitle, DialogContent, IconButton, Tooltip, Zoom
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Send as SendIcon, Close as CloseIcon, AttachFile as AttachIcon, EmojiEmotions } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import CarSearchPage from './CarSearchPage';
import { CarAttachmentList, SendButton } from './SendComponents';



const SendMessageForm = ({ onSendMessage, unsentMessage }) => {
  const [message, setMessage] = useState('');
  const [attachedCars, setAttachedCars] = useState([]);
  const [isCarSearchOpen, setIsCarSearchOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSending, setIsSending] = useState(false);

  
  useEffect(() => {
    if (unsentMessage && unsentMessage.sent === false) {
      setMessage(unsentMessage.bot_message || '');
      setAttachedCars(unsentMessage.car_stocks || []);
    }
  }, [unsentMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.trim() || attachedCars.length > 0) {
      setIsSending(true);
      await onSendMessage({ text: message, cars: attachedCars });
      setMessage('');
      setAttachedCars([]);
      setIsExpanded(false);
      setIsSending(false);
    }
  };

  const handleOpenCarSearch = () => setIsCarSearchOpen(true);
  const handleCloseCarSearch = () => setIsCarSearchOpen(false);
  const handleSelectCars = (selectedCars) => {
    setAttachedCars(selectedCars);
    handleCloseCarSearch();
  };
  const handleRemoveCar = (vehicleid) => {
    setAttachedCars(attachedCars.filter(car => car.vehicleid !== vehicleid));
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, mb: 2 }}>
      <motion.div layout>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onFocus={() => setIsExpanded(true)}
                multiline
                rows={isExpanded ? 3 : 1}
                InputProps={{
                  endAdornment: (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="Add emoji" arrow>
                        <IconButton>
                          <EmojiEmotions />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Attach cars" arrow>
                        <IconButton onClick={handleOpenCarSearch}>
                          <AttachIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Send message" arrow>
                        <SendButton 
                          type="submit" 
                          disabled={(!message.trim() && attachedCars.length === 0) || isSending}
                          className={isSending ? 'sending' : ''}
                        >
                          <SendIcon />
                        </SendButton>
                      </Tooltip>
                    </Box>
                  ),
                }}
              />
            </motion.div>
          </Grid>
          <AnimatePresence>
            {attachedCars.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Attached Cars:
                </Typography>
                <CarAttachmentList cars={attachedCars} onRemoveCar={handleRemoveCar} />
              </Grid>
            )}
          </AnimatePresence>
        </Grid>
      </motion.div>

      <Dialog 
        open={isCarSearchOpen} 
        onClose={handleCloseCarSearch} 
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={300}
      >
        <DialogTitle>
          Select Cars
          <IconButton
            aria-label="close"
            onClick={handleCloseCarSearch}
            sx={{ position: 'absolute', right: 10, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CarSearchPage onSelectCars={handleSelectCars} initialSelectedCars={attachedCars} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SendMessageForm;