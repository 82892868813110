import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Box,
  Alert,
  Tabs,
  Tab,
} from '@mui/material';

const API_BASE_URL = "https://stage-admin.aitomotivelab.com/gestionale/api/";

const DynamicProductForm = () => {
  const { warehouseId } = useParams();
  const navigate = useNavigate();
  const [warehouse, setWarehouse] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    id: null,
    name: '',
    unique_key: '',
    properties: {}
  });
  const [error, setError] = useState(null);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
  };

  const handleUnauthorized = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const fetchWithAuth = async (url, options = {}) => {
    try {
      const response = await fetch(url, {
        ...options,
        headers: {
          ...getHeaders(),
          ...(options.headers || {}),
        },
      });
  
      const data = await response.json();
      console.log('Response data:', data);
  
      if (!response.ok) {
        const error = new Error(`HTTP error! status: ${response.status}`);
        error.response = response;
        error.data = data;
        throw error;
      }
  
      return data;
    } catch (error) {
      console.error('Fetch error:', error);
      console.error('Error response:', error.response);
      console.error('Error data:', error.data);
      throw error;
    }
  };

  useEffect(() => {
    const fetchWarehouseAndProducts = async () => {
      try {
        const warehouseData = await fetchWithAuth(`${API_BASE_URL}warehouses/${warehouseId}/`);
        setWarehouse(warehouseData);

        const productsData = await fetchWithAuth(`${API_BASE_URL}products/?warehouse=${warehouseId}`);
        setProducts(productsData);
        console.log("Products:", productsData);

        initializeFormData(warehouseData.categories || []);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 403) {
          setError("You don't have permission to access this warehouse.");
        } else {
          setError(error.message || 'An error occurred while fetching data.');
        }
      }
    };

    fetchWarehouseAndProducts();
  }, [warehouseId, navigate]);
  
  const initializeFormData = (categories) => {
    const properties = {};
    categories.forEach(category => {
      properties[category.category_id] = [];
      (category.property_groups || []).forEach(group => {
        (group.properties || []).forEach(property => {
          properties[category.category_id].push({
            product_property_id: null,
            product: null,  // Initialize with null for new products
            property: property.id,
            value: ''
          });
        });
      });
    });
    setFormData({ id: null, name: '', unique_key: '', properties });
    console.log('Initialized Form Data:', JSON.stringify({ id: null, name: '', unique_key: '', properties }, null, 2));
  };

  const handleProductSelect = async (event) => {
    const productId = event.target.value;
    console.log(productId);
    if (productId) {
      try {
        const productData = await fetchWithAuth(`${API_BASE_URL}products/${productId}/`);
        setSelectedProduct(productData);
        console.log("Selected Product:", productData);
        const properties = { ...formData.properties };
        warehouse.categories.forEach(category => {
          properties[category.category_id] = (properties[category.category_id] || []).map(prop => {
            const matchingProp = productData.properties.find(p => p.property === prop.property);
            return matchingProp ? {
              ...prop,
              product_property_id: matchingProp.product_property_id,
              product: productData.id,
              value: matchingProp.value
            } : prop;
          });
        });
        setFormData({
          id: productData.id,
          name: productData.name,
          unique_key: productData.unique_key,
          properties
        });
      } catch (error) {
        console.error('Error fetching product data:', error);
        setError(error.message || 'An error occurred while fetching product data.');
      }
    } else {
      setSelectedProduct(null);
      initializeFormData(warehouse?.categories || []);
    }
  };

  const handleInputChange = (field, value) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const handlePropertyChange = (categoryId, propertyId, value) => {
    setFormData(prevData => ({
      ...prevData,
      properties: {
        ...prevData.properties,
        [categoryId]: (prevData.properties[categoryId] || []).map(prop => 
          prop.property === propertyId ? { ...prop, value } : prop
        )
      }
    }));
  };

  const renderPropertyInput = (categoryId, property) => {
    if (!property) return null;

    const propertyData = formData.properties[categoryId]?.find(p => p.property === property.id);
    const value = propertyData ? propertyData.value : '';

    switch (property.property_type) {
      case 'text':
      case 'date':
        return (
          <TextField
            fullWidth
            variant="outlined"
            type={property.property_type === 'date' ? 'date' : 'text'}
            value={value || ''}
            onChange={(e) => handlePropertyChange(categoryId, property.id, e.target.value)}
          />
        );
      case 'number':
        return (
          <TextField
            fullWidth
            type="number"
            variant="outlined"
            value={value || ''}
            onChange={(e) => handlePropertyChange(categoryId, property.id, parseFloat(e.target.value))}
          />
        );
      case 'boolean':
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={value || false}
                onChange={(e) => handlePropertyChange(categoryId, property.id, e.target.checked)}
              />
            }
            label={property.name}
          />
        );
      case 'select':
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel>{property.name}</InputLabel>
            <Select
              value={value || ''}
              onChange={(e) => handlePropertyChange(categoryId, property.id, e.target.value)}
              label={property.name}
            >
              {(property.options || []).map((option, index) => (
                <MenuItem key={index} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case 'multi_select':
        // Implement multi-select logic here
        return null;
      default:
        return null;
    }
  };

  const getPropertiesForCurrentCategory = () => {
    if (!warehouse || !warehouse.categories) return [];
    
    const currentCategory = warehouse.categories[currentCategoryIndex];
    if (!currentCategory) return [];

    let properties = [];
    const currentCategoryId = currentCategory.category_id;
    (currentCategory.property_groups || []).forEach(group => {
      group.properties.forEach(property => {
        const formProperty = formData.properties[currentCategoryId]?.find(p => p.property === property.id);
        if (formProperty) {
          properties.push({
            ...formProperty,
            name: property.name,  // Include the property name for easier debugging
            property_type: property.property_type  // Include the property type for validation
          });
        }
      });
    });

    return properties;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = selectedProduct
        ? `${API_BASE_URL}products/${selectedProduct.id}/`
        : `${API_BASE_URL}products/`;
      const method = selectedProduct ? 'PUT' : 'POST';
  
      const currentCategory = warehouse.categories[currentCategoryIndex];
      const currentCategoryProperties = getPropertiesForCurrentCategory();

      // Check if any properties in the current category have changed
      const hasChanges = currentCategoryProperties.some(prop => {
        const originalProp = selectedProduct ? 
          selectedProduct.properties.find(p => p.property === prop.property) : null;
        return !originalProp || originalProp.value !== prop.value;
      });

      if (!hasChanges && selectedProduct) {
        setError("No changes detected in the current category.");
        return;
      }

      const productData = {
        name: formData.name,
        unique_key: formData.unique_key,
        warehouse: parseInt(warehouseId),
        properties: currentCategoryProperties.map(prop => ({
          product_property_id: prop.product_property_id || null,
          product: selectedProduct ? selectedProduct.id : null,
          property: prop.property,
          value: prop.value
        })),
        category: currentCategory.category_id
      };
  
      if (selectedProduct) {
        productData.id = selectedProduct.id;
      }
  
      console.log('Sending data for category:', currentCategory.name);
      console.log('Sending data:', JSON.stringify(productData, null, 2));
  
      const result = await fetchWithAuth(url, {
        method: method,
        body: JSON.stringify(productData),
      });
  
      console.log(selectedProduct ? 'Product updated:' : 'Product created:', result);
      setError(null);
      // Handle successful submission (e.g., show success message, reset form)
      
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.data) {
        const errorMessages = parseErrorMessages(error.data);
        setError(`Validation error:\n${errorMessages}`);
      } else {
        setError(error.message || 'An error occurred while submitting the form.');
      }
    }
  };

  const parseErrorMessages = (errorData, prefix = '') => {
    if (Array.isArray(errorData)) {
      return errorData.map((item, index) => parseErrorMessages(item, `${prefix}[${index}]`)).join('\n');
    }
    
    if (typeof errorData === 'object' && errorData !== null) {
      return Object.entries(errorData)
        .map(([key, value]) => {
          if (typeof value === 'object' && value !== null) {
            return parseErrorMessages(value, `${prefix}${key}.`);
          }
          return `${prefix}${key}: ${value}`;
        })
        .join('\n');
    }
    
    return `${prefix}: ${errorData}`;
  };

  const handleCategoryChange = (event, newValue) => {
    setCurrentCategoryIndex(newValue);
  };

  const renderCategories = () => {
    if (!warehouse || !warehouse.categories || warehouse.categories.length === 0) {
      return <Typography>No categories available.</Typography>;
    }

    return (
      <>
        <Tabs
          value={currentCategoryIndex}
          onChange={handleCategoryChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {warehouse.categories.map((category, index) => (
            <Tab key={category.id} label={category.name} value={index} />
          ))}
        </Tabs>
        {renderCurrentCategory()}
      </>
    );
  };

  const renderCurrentCategory = () => {
    if (!warehouse || !warehouse.categories || warehouse.categories.length === 0) {
      return <Typography>No categories available.</Typography>;
    }

    const category = warehouse.categories[currentCategoryIndex];
    if (!category) {
      return <Typography>Category not found.</Typography>;
    }

    return (
      <Card variant="outlined">
        <CardHeader title={category.name} />
        <CardContent>
          {(category.property_groups || []).map(group => (
            <Box key={group.id} mb={2}>
              <Typography variant="h6" gutterBottom>{group.name}</Typography>
              {(group.properties || []).map(property => (
                <Box key={property.id} mb={2}>
                  <Typography variant="subtitle1" gutterBottom>{property.name}</Typography>
                  {renderPropertyInput(category.category_id, property)}
                </Box>
              ))}
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      {!warehouse ? (
        <Typography>Loading...</Typography>
      ) : (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader title={selectedProduct ? 'Edit Product' : 'Add New Product'} />
            <CardContent>
              <Box mb={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="product-select-label">Select Product (optional)</InputLabel>
                  <Select
                    labelId="product-select-label"
                    value={selectedProduct ? selectedProduct.id : ''}
                    onChange={handleProductSelect}
                    label="Select Product (optional)"
                  >
                    <MenuItem value="">New Product</MenuItem>
                    {(products || []).map((product) => (
                      <MenuItem key={product.id} value={product.id}>
                        {product.id+"-"+product.name +" - " + product.unique_key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Product Name"
                  variant="outlined"
                  value={formData.name}
                  onChange={(e) => handleInputChange('name', e.target.value)}
                  required
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Unique Key"
                  variant="outlined"
                  value={formData.unique_key}
                  onChange={(e) => handleInputChange('unique_key', e.target.value)}
                  required
                />
              </Box>
              
              {renderCategories()}

              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button type="submit" variant="contained" color="primary">
                  {selectedProduct ? 'Update Product' : 'Add Product'}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </>
  );
};

export default DynamicProductForm;
