// Header.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Header = ({ totalAccesses }) => (
  <Box sx={{ mb: { xs: 5, md: 10 } }}>
    <Typography variant="h2" sx={{ display: { xs: 'none', md: 'block' } }}> Ruoli e App </Typography>
    <Typography variant="h7" sx={{ pl: 1, display: { xs: 'none', md: 'block' } }}>
      <b style={{ color: '#2BD900' }}>{totalAccesses}</b> Accessi totali
    </Typography>
  </Box>
);

export default Header;