import React, { useState, useEffect, useRef } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Snackbar,
  CircularProgress,
  Chip,
  List,
  ListItem,
  Divider,
  Box,
  Paper,
  Avatar,
  IconButton,
  Tooltip,
  Fade,
  Zoom,
  Slide,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Person as PersonIcon,
  SmartToy as AIIcon,
  DirectionsCar as CarIcon,
  CheckCircle as CheckIcon,
  Error as ErrorIcon,
  ThumbUp,
  ThumbDown,
  Comment as CommentIcon,
  ExpandMore as ExpandMoreIcon,
  Label as LabelIcon,
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import MuiAlert from '@mui/material/Alert';
import { useAuth } from '../contexts/AuthContext';
import SendMessageForm from './SendMessageForm';
import {
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  AudioFile as AudioIcon,
  BrokenImage as BrokenImageIcon,

} from '@mui/icons-material';

const WSS_BASE_URL = process.env.REACT_APP_WSS_BASE_URL || 'wss://stage-admin.aitomotivelab.com';
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || 'https://stage-admin.aitomotivelab.com';


// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f5f5f5',
  boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
  borderRadius: 16,
  overflow: 'hidden',
  position: 'relative',
  height: 'calc(100vh - 100px)',
  display: 'flex',
  flexDirection: 'column',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, #FF6B6B, #4ECDC4, #45B7D1)',
  },
}));

const MessageList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: '20px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.primary.dark,
  },
}));

const FixedBottomBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  zIndex: 1,
}));

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'scale(1.2) rotate(5deg)',
    backgroundColor: theme.palette.primary.light,
  },
}));

const MessagePaper = styled(Paper)(({ theme, isBot, isWhatsApp }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  backgroundColor: isBot
    ? isWhatsApp
      ? '#DCF8C6'
      : theme.palette.primary.light
    : isWhatsApp
    ? '#E2E2E2'
    : theme.palette.background.paper,
  color: isBot
    ? isWhatsApp
      ? '#000000'
      : theme.palette.primary.contrastText
    : theme.palette.text.primary,
  borderRadius: 16,
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
  },
  maxWidth: '80%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

const SummaryAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: '16px',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: theme.spacing(2, 0),
  },
}));

const SummaryAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2A2F3B' : '#E8F0FE',
  borderRadius: '16px',
  '&.Mui-expanded': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const SummaryAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9FAFB',
  borderBottomLeftRadius: '16px',
  borderBottomRightRadius: '16px',
}));

const ChatHistoryDetail = ({ chatId, humanControl }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [chatHistory, setChatHistory] = useState([]);
  const [chatSummary, setChatSummary] = useState('');
  const [labelSegmentation, setLabelSegmentation] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: 'success' });
  const [feedbackDialog, setFeedbackDialog] = useState({ open: false, messageId: null });
  const [feedback, setFeedback] = useState({ category: '', comment: '' });
  const lastMessageRef = useRef(null);
  const [unsentMessage, setUnsentMessage] = useState(null);
  const [summaryExpanded, setSummaryExpanded] = useState(false);
  const wsRef = useRef(null);
  const [authError, setAuthError] = useState(false);
  const isManuallyClosed = useRef(false);
  const reconnectionAttempts = useRef(0);
  const tempIdCounter = useRef(0);

  const { userRole, appAccess, token = localStorage.getItem('token') } = useAuth();
  const permissions = appAccess['ai_app'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';


  const FileDisplay = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  }));

  useEffect(() => {
    let isCancelled = false;
    isManuallyClosed.current = false;
    setLoading(true);
    setChatHistory([]);
    setChatSummary('');
    setLabelSegmentation('');

    const connectWebSocket = () => {
      if (wsRef.current) {
        wsRef.current.close();
      }

      wsRef.current = new WebSocket(`${WSS_BASE_URL}/ws/admin_chat/?token=${token}`);

      wsRef.current.onopen = () => {
        console.log('WebSocket Connected');
        setAuthError(false);
        reconnectionAttempts.current = 0;
        wsRef.current.send(JSON.stringify({ action: 'full_chat', pk: chatId }));
      };

      wsRef.current.onmessage = (event) => {
        let data;
        try {
          data = JSON.parse(event.data);
        } catch (e) {
          console.error('Failed to parse message', e);
          return;
        }
        if (data.type === 'error' && data.message === 'Authentication required.') {
          setAuthError(true);
          wsRef.current.close();
        } else {
          console.log("MESSAGE RECEIVEW", data)
          handleWebSocketMessage(data);
        }
      };

      wsRef.current.onerror = (error) => {
        console.error('WebSocket Error:', error);
        setError('Error connecting to chat');
      };

      wsRef.current.onclose = (event) => {
        console.log('WebSocket Disconnected', event.code, event.reason);
        if (!authError && !isManuallyClosed.current && !isCancelled) {
          reconnectionAttempts.current += 1;
          const delay = Math.min(10000, 1000 * Math.pow(2, reconnectionAttempts.current));
          setTimeout(() => connectWebSocket(), delay);
        }
      };
    };

    connectWebSocket();

    return () => {
      isCancelled = true;
      isManuallyClosed.current = true;
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [chatId, token]);

  const handleWebSocketMessage = (data) => {
    switch (data.action || data.type) {
      case 'full_chat':
        setChatHistory(data.data);
        setChatSummary(data.summary || '');
        setLabelSegmentation(data.label_segmentation || '');
        setLoading(false);
        break;
      case 'new_message':
      case 'update_chat':
        const incomingMessage = data.data;
        setChatHistory((prevHistory) => {
          const index = prevHistory.findIndex(
            (msg) => msg.id === incomingMessage.id || msg.id === incomingMessage.messageId
          );
          if (index !== -1) {
            const updatedHistory = [...prevHistory];
            updatedHistory[index] = { ...updatedHistory[index], ...incomingMessage };
            return updatedHistory;
          } else {
            return [...prevHistory, incomingMessage];
          }
        });
        break;
      case 'message_sent':
        const { chatHistoryId, temp_id } = data;
        setChatHistory((prevHistory) => {
          const index = prevHistory.findIndex((msg) => msg.id === temp_id);
          if (index !== -1) {
            const updatedMessage = {
              ...prevHistory[index],
              id: chatHistoryId,
              isTemp: false,
            };
            const newHistory = [...prevHistory];
            newHistory[index] = updatedMessage;
            return newHistory;
          }
          return prevHistory;
        });
        setUnsentMessage(null);
        break;
      case 'message_status_updated':
        const { chatHistoryId: updatedId, sent } = data;
        setChatHistory((prevHistory) => {
          const index = prevHistory.findIndex((msg) => msg.id === updatedId);
          if (index !== -1) {
            const updatedMessage = {
              ...prevHistory[index],
              sent: sent,
            };
            const newHistory = [...prevHistory];
            newHistory[index] = updatedMessage;
            return newHistory;
          }
          return prevHistory;
        });
        break;
      default:
        console.log("data",)
        console.log('Unhandled WebSocket message:', data);
    }
  };

  useEffect(() => {
    if (chatHistory.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  useEffect(() => {
    const lastMessage = chatHistory[chatHistory.length - 1];
    if (lastMessage && lastMessage.sent === false && lastMessage.sender_message) {
      setUnsentMessage(lastMessage);
    } else {
      setUnsentMessage(null);
    }
  }, [chatHistory]);

  const sendWebSocketMessage = (message) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not open. Cannot send message.');
      setSnackbar({ open: true, message: 'Error: WebSocket is not connected', type: 'error' });
    }
  };

  const handleSendMessage = (message) => {
    // Check if there is a last unsent message
    if (unsentMessage) {
      // Update the last unsent message to sent
      sendWebSocketMessage({
        action: 'update_message_status',
        chatHistoryId: unsentMessage.id,
        sent: true,
      });
    }

    const tempId = `temp-${tempIdCounter.current++}`;
    const tempMessage = {
      id: tempId,
      bot_message: message.text,
      created_at: new Date().toISOString(),
      sent: true,
      isTemp: true,
      car_stocks: message.cars,
    };
    setChatHistory((prevHistory) => [...prevHistory, tempMessage]);

    sendWebSocketMessage({
      action: 'send_message',
      chatId: chatId,
      messageId: tempId,
      message: message.text,
      car_stocks: message.cars,
      sent: true,
      include_registration_form: message.include_registration_form,

    });
  };

  const handleFeedback = (messageId, type) => {
    if (type === 'comment') {
      setFeedbackDialog({ open: true, messageId });
    } else {
      sendWebSocketMessage({
        action: 'send_feedback',
        messageId: messageId,
        feedbackType: type,
      });
    }
  };

  const handleFeedbackSubmit = () => {
    sendWebSocketMessage({
      action: 'send_feedback',
      messageId: feedbackDialog.messageId,
      feedback: feedback,
    });
    setFeedbackDialog({ open: false, messageId: null });
    setFeedback({ category: '', comment: '' });
  };

  const handleEdit = (message) => {
    setEditingMessage(message);
    setEditing(true);
  };

  const handleSave = () => {
    sendWebSocketMessage({
      action: 'edit_message',
      message: editingMessage,
    });
    setEditing(false);
    setEditingMessage(null);
  };

  const handleChange = (e) => {
    setEditingMessage({ ...editingMessage, [e.target.name]: e.target.value });
  };

  const MarkdownContent = ({ content }) => (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props }) => <Typography variant="h4" gutterBottom {...props} />,
        h2: ({ node, ...props }) => <Typography variant="h5" gutterBottom {...props} />,
        h3: ({ node, ...props }) => <Typography variant="h6" gutterBottom {...props} />,
        p: ({ node, ...props }) => <Typography variant="body1" paragraph {...props} />,
        a: ({ node, ...props }) => (
          <a style={{ color: theme.palette.primary.main }} {...props} />
        ),
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );

  const CarStockCard = ({ car }) => (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.2 }}
    >
      <Card
        sx={{
          maxWidth: 'xl',
          m: 1,
          boxShadow: 3,
          borderRadius: 4,
          overflow: 'hidden',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardMedia
          component="img"
          height="140"
          image={car.urlmainimage || '/api/placeholder/345/140'}
          alt={`${car.brand} ${car.model}`}
        />
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6" component="div" gutterBottom>
            {car.brand} {car.model}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {car.version}
          </Typography>
          <Typography variant="body2" color="text.primary" gutterBottom>
            ID: {car.vehicleid}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CarIcon />}
            sx={{ mt: 'auto', borderRadius: 20 }}
            onClick={() => console.log(`View details for car ${car.vehicleid}`)}
          >
            View Details
          </Button>
        </CardContent>
      </Card>
    </motion.div>
  );

  const ChatSummaryAccordion = () => (
    <SummaryAccordion
      expanded={summaryExpanded}
      onChange={() => setSummaryExpanded(!summaryExpanded)}
    >
      <SummaryAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="chat-summary-content"
        id="chat-summary-header"
      >
        <Typography variant="h6">Chat Summary</Typography>
      </SummaryAccordionSummary>
      <SummaryAccordionDetails>
        <Typography>{chatSummary}</Typography>
      </SummaryAccordionDetails>
    </SummaryAccordion>
  );


  const getFileUrl = (fileObject) => {
    if (!fileObject) return null;
    
    // If fileObject is a string, return it directly
    if (typeof fileObject === 'string') return fileObject;
    
    // If fileObject is an object with a file_url property, return that
    if (typeof fileObject === 'object' && fileObject.file_url) {
      return fileObject.file_url;
    }
    
    // If we can't determine the URL, return null
    console.error('Unable to extract file URL from:', fileObject);
    return null;
  };

  const getAbsoluteUrl = (relativeUrl) => {
    if (!relativeUrl) return null;
    
    // Convert to string in case it's not already
    const urlString = String(relativeUrl);
    
    if (urlString.startsWith('http://') || urlString.startsWith('https://')) {
      return urlString; // It's already an absolute URL
    }
    return `${API_DOMAIN}${urlString.startsWith('/') ? '' : '/'}${urlString}`;
  };

  const getFileDetails = (fileUrl) => {
    if (!fileUrl) {
      console.error('Invalid fileUrl:', fileUrl);
      return { fileName: 'Unknown File', fileExtension: '' };
    }
    
    const urlString = String(fileUrl);
    const urlParts = urlString.split('/');
    const fileName = urlParts[urlParts.length - 1];
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return { fileName, fileExtension };
  };

  const renderFile = (fileObject, isBot) => {
    const fileUrl = getFileUrl(fileObject);
    if (!fileUrl) {
      return null;
    }

    const absoluteUrl = getAbsoluteUrl(fileUrl);
    if (!absoluteUrl) {
      console.error('Unable to generate absolute URL for:', fileUrl);
      return null;
    }

    const { fileName, fileExtension } = getFileDetails(absoluteUrl);
    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
    const isAudio = ['mp3', 'wav', 'ogg'].includes(fileExtension);

    return (
      <FileDisplay>
        {isImage ? (
          <img 
            src={absoluteUrl} 
            alt="Shared file" 
            style={{ maxWidth: '100%', maxHeight: '200px' }} 
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${API_DOMAIN}/path/to/fallback/image.png`; // Replace with actual fallback image path
            }}
          />
        ) : isAudio ? (
          <audio controls src={absoluteUrl}>
            Your browser does not support the audio element.
          </audio>
        ) : (
          <>
            <FileIcon color="primary" />
            <Typography variant="body2">{fileName}</Typography>
          </>
        )}
        <Tooltip title="Download file">
          <IconButton href={absoluteUrl} download>
            {isImage ? <ImageIcon /> : isAudio ? <AudioIcon /> : <FileIcon />}
          </IconButton>
        </Tooltip>
      </FileDisplay>
    );
  };



  const LabelSegmentationAccordion = () => (
    <SummaryAccordion>
      <SummaryAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="label-segmentation-content"
        id="label-segmentation-header"
      >
        <Typography variant="h6">
          <LabelIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
          Label Segmentation
        </Typography>
      </SummaryAccordionSummary>
      <SummaryAccordionDetails>
        <Typography>{labelSegmentation}</Typography>
      </SummaryAccordionDetails>
    </SummaryAccordion>
  );

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  if (error)
    return (
      <Typography color="error" variant="h5" align="center">
        {error}
      </Typography>
    );
  if (chatHistory.length === 0)
    return (
      <Typography variant="h5" align="center">
        No chat history found
      </Typography>
    );

  return (
    <Container maxWidth={isSmallScreen ? 'sm' : isMediumScreen ? 'md' : 'lg'}>
      <StyledPaper elevation={3}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: theme.palette.primary.main,
            textAlign: 'center',
            mb: 4,
            fontWeight: 'bold',
            textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          Complete Conversation
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 4 }}>
          <ChatSummaryAccordion />
          <LabelSegmentationAccordion />
        </Box>

        <MessageList>
          <AnimatePresence>
            {[...chatHistory]
              .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
              .map((message, index) => (
                <motion.div
                  key={message.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  ref={index === chatHistory.length - 1 ? lastMessageRef : null}
                >
                  <ListItem alignItems="flex-start" sx={{ flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', width: '100%', mb: 2, alignItems: 'flex-start' }}>
                      <Zoom in timeout={500}>
                        <Avatar
                          sx={{
                            bgcolor: message.sender_message
                              ? theme.palette.secondary.main
                              : theme.palette.primary.main,
                            mr: 2,
                            width: 48,
                            height: 48,
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              transform: 'scale(1.1)',
                            },
                          }}
                        >
                          {message.sender_message ? <PersonIcon /> : <AIIcon />}
                        </Avatar>
                      </Zoom>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: theme.palette.text.secondary, mb: 1 }}
                        >
                          {new Date(message.created_at).toLocaleString()}
                        </Typography>
                        {message.sender_message && (
                          <Fade in timeout={700}>
                            <MessagePaper
                              elevation={1}
                              isBot={false}
                              isWhatsApp={message.whatsapp}
                              style={message.isTemp ? { opacity: 0.7 } : {}}
                              sx={{ alignSelf: 'flex-start' }}
                            >
                              <MarkdownContent content={message.sender_message} />
                            </MessagePaper>
                          </Fade>
                        )}

                        {message.sender_file && (
                            <Box sx={{ alignSelf: 'flex-start', maxWidth: '80%' }}>
                              {renderFile(message.sender_file, false)}
                            </Box>
                          )}

                        {message.bot_message && message.sent && (
                          <Slide in direction="right" timeout={500}>
                            <MessagePaper
                              elevation={1}
                              isBot={true}
                              isWhatsApp={message.whatsapp}
                              sx={{ alignSelf: 'flex-end' }}
                            >
                              <Box>
                                <MarkdownContent content={message.bot_message} />
                              </Box>
                            </MessagePaper>
                          </Slide>
                        )}

                  
                          
                          {message.bot_file && message.sent && (
                            <Box sx={{ alignSelf: 'flex-end', maxWidth: '80%' }}>
                              {renderFile(message.bot_file, true)}
                            </Box>
                          )}

                        {message.car_stocks &&
                          message.car_stocks.length > 0 &&
                          message.sent && (
                            <Box sx={{ mt: 2 }}>
                              <Typography variant="h6" gutterBottom>
                                Available Cars:
                              </Typography>
                              <Grid container spacing={2}>
                                {message.car_stocks.map((car) => (
                                  <Grid item xs={12} sm={6} md={4} key={car.vehicleid}>
                                    <CarStockCard car={car} />
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                          )}
                      </Box>
                      <Box>
                        {!editing && canEdit && !message.isTemp && (
                          <Tooltip title="Edit">
                            <AnimatedIconButton
                              onClick={() => handleEdit(message)}
                              size="small"
                            >
                              <EditIcon />
                            </AnimatedIconButton>
                          </Tooltip>
                        )}
                        {editing && editingMessage?.id === message.id && canEdit && (
                          <Tooltip title="Save">
                            <AnimatedIconButton
                              onClick={handleSave}
                              size="small"
                              color="primary"
                            >
                              <SaveIcon />
                            </AnimatedIconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        flexWrap: 'wrap',
                      }}
                    >
                      {message.category && (
                        <Chip
                          label={`Category: ${message.category}`}
                          size="small"
                          sx={{
                            alignSelf: 'flex-start',
                            mb: 1,
                            borderRadius: 16,
                            background:
                              'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                            color: 'white',
                            fontWeight: 'bold',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              transform: 'translateY(-2px)',
                              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                            },
                          }}
                        />
                      )}
                      {canEdit && !message.isTemp && (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
                          <Tooltip title="Helpful">
                            <IconButton
                              size="small"
                              onClick={() => handleFeedback(message.id, 'helpful')}
                            >
                              <ThumbUp fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Not Helpful">
                            <IconButton
                              size="small"
                              onClick={() => handleFeedback(message.id, 'not_helpful')}
                            >
                              <ThumbDown fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Add Comment">
                            <IconButton
                              size="small"
                              onClick={() => handleFeedback(message.id, 'comment')}
                            >
                              <CommentIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </ListItem>
                  {index < chatHistory.length - 1 && (
                    <Divider
                      variant="fullWidth"
                      sx={{
                        my: 2,
                        backgroundImage:
                          'linear-gradient(to right, transparent, rgba(0,0,0,0.1), transparent)',
                      }}
                    />
                  )}
                </motion.div>
              ))}
          </AnimatePresence>
        </MessageList>
        {canEdit && humanControl && (
          <FixedBottomBox>
            <SendMessageForm
              onSendMessage={handleSendMessage}
              unsentMessage={unsentMessage}
            />
          </FixedBottomBox>
        )}
      </StyledPaper>
      <Dialog
        open={feedbackDialog.open}
        onClose={() => setFeedbackDialog({ open: false, messageId: null })}
        TransitionComponent={Zoom}
      >
        <DialogTitle>Provide Feedback</DialogTitle>
        <DialogContent>
          <Select
            value={feedback.category}
            onChange={(e) => setFeedback({ ...feedback, category: e.target.value })}
            fullWidth
            displayEmpty
            sx={{ mt: 2, mb: 2 }}
          >
            <MenuItem value="" disabled>
              Select a category
            </MenuItem>
            <MenuItem value="helpful">Helpful</MenuItem>
            <MenuItem value="not_helpful">Not Helpful</MenuItem>
            <MenuItem value="inaccurate">Inaccurate</MenuItem>
            <MenuItem value="offensive">Offensive</MenuItem>
          </Select>
          <TextField
            multiline
            rows={4}
            value={feedback.comment}
            onChange={(e) => setFeedback({ ...feedback, comment: e.target.value })}
            fullWidth
            placeholder="Add your comment here..."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFeedbackDialog({ open: false, messageId: null })}>
            Cancel
          </Button>
          <Button onClick={handleFeedbackSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.type}
          sx={{ width: '100%' }}
          icon={snackbar.type === 'success' ? <CheckIcon /> : <ErrorIcon />}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ChatHistoryDetail;