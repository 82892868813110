import axios from 'axios';

const DOMAIN = process.env.REACT_APP_API_URL || 'https://stage-admin.aitomotivelab.com'
const API_URL = `${DOMAIN}/user_management/api`;  // Replace with your actual API URL

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    // Add any authentication headers here, e.g.:
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  }
});

export const fetchUsers = async () => {
  try {
    const response = await api.get('/users/');
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await api.post('/users/', userData);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await api.put(`/users/${userId}/`, userData);
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    await api.delete(`/users/${userId}/`);
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export const fetchCurrentUserRole = async () => {
  try {
    const response = await api.get('/users/me/');  // Assuming you have an endpoint for the current user
    return response.data;
  } catch (error) {
    console.error('Error fetching current user role:', error);
    throw error;
  }
};

export const updateUserPassword = async (userId, newPassword) => {
  try {
    const response = await api.post(`/users/${userId}/set_password/`, { password: newPassword });
    return response.data;
  } catch (error) {
    console.error('Error updating password:', error);
    throw error;
  }
};

// New functions
export const fetchCompanies = async () => {
  try {
    const response = await api.get('/companies/');
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw error;
  }
};

export const fetchRoles = async () => {
  try {
    const response = await api.get('/roles/');
    return response.data;
  } catch (error) {
    console.error('Error fetching roles:', error);
    throw error;
  }
};

const fetchCarStockFields = async () => {
  try {
    const response = await api.get('/car-stock-fields/');
    return response.data
  } catch (error) {
    console.error('Error fetching CarStock fields:', error);
  }
};

const fetchDistinctValues = async (field) => {
  if (distinctValues[field]) return;
  try {
    const response = await api.get(`/api/car-stock-distinct-values/${field}/`);
    return response.data
  } catch (error) {
    console.error(`Error fetching distinct values for ${field}:`, error);
  }
};