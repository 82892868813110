import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ConditionTree from './ConditionTree';
import ItalianLocationFields from './ItalianLocationFields';
import { useAuth } from '../contexts/AuthContext';

// Field type options
const fieldTypes = [
  { value: 'text', label: 'Text' },
  { value: 'number', label: 'Number' },
  { value: 'email', label: 'Email' },
  { value: 'textarea', label: 'Text Area' },
  { value: 'select', label: 'Dropdown' },
  { value: 'radio', label: 'Radio Buttons' },
  { value: 'checkbox', label: 'Checkboxes' },
  { value: 'date', label: 'Date' },
  { value: 'italian_location', label: 'Italian Location' },
];

const FieldDialog = ({ isOpen, onClose, field, setField, onSave, pages }) => {
  const { canEdit, canSave } = useAuth(); // Fetch permissions from useAuth

  const formatConditions = (conditions) => {
    if (!Array.isArray(conditions) || conditions.length === 0) {
      return [];
    }
    if (conditions.length === 1 && !conditions[0].logicalOperator) {
      return [{
        logicalOperator: 'AND',
        conditions: conditions
      }];
    }
    return conditions;
  };

  const sanitizedField = useMemo(() => ({
    ...field,
    conditions: formatConditions(field.conditions || []),
    conditional_next_pages: formatConditions(field.conditional_next_pages || []),
    next_page_id: field.next_page_id || '', // Ensure next_page_id is initialized
    group_id: field.group_id || '', // Ensure group_id is initialized
    field_type: field.field_type || 'text' // Ensure field_type is initialized
  }), [field]);

  useEffect(() => {
    setField(sanitizedField);
  }, [setField, sanitizedField]);

  const handleFieldChange = (key, value) => {
    if (!canEdit) return; // Prevent editing if user doesn't have permission
    setField(prevField => ({ ...prevField, [key]: value }));
  };

  const handleSave = () => {
    if (!canSave) return; // Prevent saving if user doesn't have permission
    const backendFormat = {
      ...sanitizedField,
      conditions: sanitizedField.conditions.flatMap(group => group.conditions),
      conditional_next_pages: sanitizedField.conditional_next_pages.flatMap(group => group.conditions)
    };
    onSave(backendFormat);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{field.field_id ? 'Edit Field' : 'Add New Field'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Field Label"
          type="text"
          fullWidth
          value={sanitizedField.label}
          onChange={(e) => handleFieldChange('label', e.target.value)}
          disabled={!canEdit} // Disable editing if no permission
        />
        <TextField
          margin="dense"
          label="Field Description"
          type="text"
          fullWidth
          value={sanitizedField.description}
          onChange={(e) => handleFieldChange('description', e.target.value)}
          disabled={!canEdit}
        />
        <TextField
          margin="dense"
          label="Keyword"
          type="text"
          fullWidth
          value={sanitizedField.keyword}
          onChange={(e) => handleFieldChange('keyword', e.target.value)}
          disabled={!canEdit}
        />
        <FormControl fullWidth margin="dense" disabled={!canEdit}>
          <InputLabel>Field Type</InputLabel>
          <Select
            value={sanitizedField.field_type}
            onChange={(e) => handleFieldChange('field_type', e.target.value)}
          >
            {fieldTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {['select', 'radio', 'checkbox'].includes(sanitizedField.field_type) && (
          <TextField
            margin="dense"
            label="Options (comma-separated)"
            type="text"
            fullWidth
            value={sanitizedField.options}
            onChange={(e) => handleFieldChange('options', e.target.value)}
            helperText="Enter options separated by commas"
            disabled={!canEdit}
          />
        )}
        
        {sanitizedField.field_type === 'italian_location' && (
          <ItalianLocationFields
            onChange={(subField, value) => handleFieldChange(subField, value)}
            disabled={!canEdit}
          />
        )}

        <FormControl fullWidth margin="dense" disabled={!canEdit}>
          <InputLabel>Next Page</InputLabel>
          <Select
            value={sanitizedField.next_page_id}
            onChange={(e) => handleFieldChange('next_page_id', e.target.value)}
          >
            <MenuItem value="">None</MenuItem>
            {pages.map((page) => (
              <MenuItem key={page.page_id} value={page.page_id}>
                {page.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense" disabled={!canEdit}>
          <InputLabel>Group</InputLabel>
          <Select
            value={sanitizedField.group_id}
            onChange={(e) => handleFieldChange('group_id', e.target.value)}
          >
            <MenuItem value="">None</MenuItem>
            {pages.flatMap(page => page.groups).map((group) => (
              <MenuItem key={group.group_id} value={group.group_id}>
                {group.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Checkbox checked={sanitizedField.required} onChange={(e) => handleFieldChange('required', e.target.checked)} />}
          label="Required"
          disabled={!canEdit}
        />
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Conditions</Typography>
          <ConditionTree
            tree={sanitizedField.conditions}
            fields={pages.flatMap(page => page.groups.flatMap(group => group.fields))}
            onTreeChange={(newTree) => handleFieldChange('conditions', newTree)}
            showApiCondition={true}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Conditional Next Pages</Typography>
          <ConditionTree
            tree={sanitizedField.conditional_next_pages}
            fields={pages.flatMap(page => page.groups.flatMap(group => group.fields))}
            onTreeChange={(newTree) => handleFieldChange('conditional_next_pages', newTree)}
            showNextPage={true}
            pages={pages}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={!canSave}>Save</Button> {/* Disable save if user lacks permission */}
      </DialogActions>
    </Dialog>
  );
};

FieldDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  field: PropTypes.shape({
    field_id: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    keyword: PropTypes.string,
    field_type: PropTypes.string,
    options: PropTypes.string,
    next_page_id: PropTypes.string,
    group_id: PropTypes.string,
    required: PropTypes.bool,
    conditions: PropTypes.array,
    conditional_next_pages: PropTypes.array,
  }).isRequired,
  setField: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({
    page_id: PropTypes.string,
    label: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.shape({
      group_id: PropTypes.string,
      label: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.shape({
        field_id: PropTypes.string,
        label: PropTypes.string,
      })),
    })),
  })).isRequired,
};

export default FieldDialog;
