import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Typography } from '@mui/material';
import { updateUserPassword } from '../api/user';

const ChangePasswordDialog = ({ open, handleClose, currentUserId, error, setError }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handlePasswordSave = async () => {
        if (newPassword !== confirmPassword) {
            setError('Le password non corrispondono');
            return;
        }

        try {
            await updateUserPassword(currentUserId, newPassword);
            handleClose();
            // Optionally, you can show a success message here
        } catch (error) {
            console.error('Errore nell\'aggiornamento della password:', error);
            setError('Errore nell\'aggiornamento della password. Per favore riprova.');
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Cambia la tua Password</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Inserisci la nuova password per l'utente.
                </DialogContentText>
                {error && <Typography color="error">{error}</Typography>}
                <TextField
                    autoFocus
                    margin="dense"
                    label="Nuova Password"
                    type="password"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Conferma Nuova Password"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annulla
                </Button>
                <Button onClick={handlePasswordSave} color="primary">
                    Salva
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangePasswordDialog;

