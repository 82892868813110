import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const SnackbarNotification = ({ snackbar, handleSnackbarClose }) => {
  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarNotification;
