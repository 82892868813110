import React from 'react';
import { Grid, TextField, Button, Paper, Typography } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';

const AddtionalInformationForm = ({
  addtionalInformationFormData,
  handleAddtionalInformationFormChange,
  handleAddtionalInformationFormSubmit,
  isEditingAddtionalInformation,
}) => {
  const { userRole, appAccess } = useAuth(); // Access user role and permissions
  const permissions = appAccess['booking'] || {}; // Assuming this relates to booking permissions
  const canCreate = permissions.can_create || userRole === 'superadmin'; // Create permission
  const canEdit = permissions.can_edit || userRole === 'superadmin'; // Edit permission

  // Determine if the user has permission to either create or edit
  const canSubmitForm = isEditingAddtionalInformation ? canEdit : canCreate;

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Typography variant="h6" gutterBottom>
        {isEditingAddtionalInformation ? 'Edit Additional Information' : 'Add New Additional Information'}
      </Typography>
      <form onSubmit={handleAddtionalInformationFormSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              label="Name"
              value={addtionalInformationFormData.name}
              onChange={handleAddtionalInformationFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="description"
              label="Description"
              value={addtionalInformationFormData.description}
              onChange={handleAddtionalInformationFormChange}
              fullWidth
              multiline
              rows={4}
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12}>
            {canSubmitForm && ( // Show the button only if user has permission to submit
              <Button type="submit" variant="contained" color="primary">
                {isEditingAddtionalInformation ? 'Update Information' : 'Add Information'}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AddtionalInformationForm;
