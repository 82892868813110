import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  CircularProgress, 
  Grid, 
  useTheme, 
  useMediaQuery 
} from '@mui/material';
import { styled } from '@mui/system';
import { MessageSquare, AlertTriangle } from 'lucide-react';
import Chats from './Chats';  // Assuming this component exists and is compatible
import { useAuth } from '../contexts/AuthContext';

const FullHeightBox = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));

const StyledPaper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  boxShadow: theme.shadows[3],
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
}));

const CenteredBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const ChatsPage = () => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const token = localStorage.getItem('token');
        let API_BASE_URL = "https://stage-admin.aitomotivelab.com";
        let apiUrl = `${API_BASE_URL}/ai_app/chat-histories/get_all_chats`;
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('API response:', data);
        
        if (Array.isArray(data)) {
          setChats(data);
        } else if (data && typeof data === 'object') {
          const chatArray = Object.values(data).find(Array.isArray);
          if (chatArray) {
            setChats(chatArray);
          } else {
            throw new Error('No array found in the response');
          }
        } else {
          throw new Error('Unexpected data format');
        }
      } catch (error) {
        console.error('Error fetching chats:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchChats();
  }, []);

  const handleChatClick = (chatId) => {
    console.log(`Chat clicked: ${chatId}`);
  };

  return (
    <FullHeightBox>
      <Typography 
        variant={isMobile ? "h5" : "h4"} 
        component="h1" 
        gutterBottom 
        sx={{ mb: 4, fontWeight: 'bold', color: theme.palette.primary.main }}
      >
        Chat History
      </Typography>
      <StyledPaper>
        {loading ? (
          <CenteredBox>
            <CircularProgress size={60} />
          </CenteredBox>
        ) : error ? (
          <CenteredBox>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item>
                <AlertTriangle size={40} color={theme.palette.error.main} />
              </Grid>
              <Grid item>
                <Typography color="error" variant="h6">Error: {error}</Typography>
              </Grid>
            </Grid>
          </CenteredBox>
        ) : chats.length > 0 ? (
          <Chats chats={chats} onChatClick={handleChatClick} />
        ) : (
          <CenteredBox>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item>
                <MessageSquare size={40} color={theme.palette.text.secondary} />
              </Grid>
              <Grid item>
                <Typography variant="h6" color="textSecondary">No chats found.</Typography>
              </Grid>
            </Grid>
          </CenteredBox>
        )}
      </StyledPaper>
    </FullHeightBox>
  );
};

export default ChatsPage;