import React, { useState } from 'react';
import { Box, Grid, Link, TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { loginUser } = useAuth();
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await loginUser(username, password); // Assuming loginUser returns the login response
       // Adjust this according to your API response structure
      
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
      // Handle login error (e.g., show error message to user)
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 5 }}>
      <TextField
        margin="normal"
        variant="standard"
        color="third"
        sx={{ color: '#FFFBDB' }}
        required
        fullWidth
        id="username"
        label="Username"
        name="username"
        autoComplete="username"
        autoFocus
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        margin="normal"
        variant="standard"
        color="third"
        required
        fullWidth
        name="password"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        id="password"
        autoComplete="current-password"
        value={password}
        onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
              <Grid item sx={{ mt: {xs: 1, md: 1}, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
          <Link href="#" variant="body3" color="#FFFBDB">
            Password dimenticata?
          </Link>
        </Grid>
      <div className="container" onClick={handleSubmit}>
        <a href="#" className="button type--A">
          <div className="button__line"></div>
          <div className="button__line"></div>
          <span className="button__text">ACCEDI</span>
          <div className="button__drow1"></div>
          <div className="button__drow2"></div>
        </a>
      </div>
      <Grid container sx={{ mt: 2, display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: '10px' }}>
        <Grid item>
          Non hai un account?{' '}
          <Link href="#" variant="body3" color="#FFFBDB">
            {"Registrati"}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;