import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, List, ListItem, ListItemText, Box, CircularProgress, Typography } from '@mui/material';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth hook for permissions

const BookingDialog = ({ open, availableSlots, selectedSlot, onSlotSelect, onClose, onSubmit, fetchClients }) => {
  const { userRole, appAccess } = useAuth(); // Get user role and app access
  const permissions = appAccess['booking'] || {}; // Extract booking-related permissions
  const canCreate = permissions.can_create || userRole === 'superadmin'; // Only superadmin or users with create access can book appointments

  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [carModel, setCarModel] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState({});
  const [notes, setNotes] = useState('');

  // Debounced function for fetching clients
  const debouncedFetchClients = useCallback(
    debounce(async (query) => {
      if (query.trim() === '') {
        setClients([]);
        return;
      }
      setIsSearching(true);
      try {
        const fetchedClients = await fetchClients(query);
        setClients(Array.isArray(fetchedClients.results) ? fetchedClients.results : []);
      } catch (error) {
        console.error('Error fetching clients:', error);
        setClients([]);
      } finally {
        setIsSearching(false);
      }
    }, 300),
    [fetchClients]
  );

  // Fetch clients whenever the search query changes
  useEffect(() => {
    debouncedFetchClients(searchQuery);
  }, [searchQuery, debouncedFetchClients]);

  // Handle client selection change
  const handleClientChange = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
  };

  // Handle change in additional information fields
  const handleAdditionalInfoChange = (id, value) => {
    setAdditionalInfo((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // Submit form data, including additional information and notes
  const handleSubmit = () => {
    const additionalInformationArray = Object.keys(additionalInfo).map((key) => ({
      add_inf: key,
      description: additionalInfo[key],
    }));

    onSubmit({
      clientId: selectedClient,
      slot: selectedSlot,
      carModel,
      additionalInfo: additionalInformationArray, // Send as array
      notes,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Select a Client and Available Slot</DialogTitle>
      <DialogContent>
        {/* Search Clients */}
        <Box mb={2}>
          <TextField
            label="Search Clients"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            margin="normal"
            placeholder="Search by client name or email"
          />
        </Box>

        {/* Select Client */}
        <Box mb={2}>
          <TextField
            select
            label="Select Client"
            value={selectedClient}
            onChange={handleClientChange}
            fullWidth
            disabled={isSearching}
          >
            {isSearching ? (
              <MenuItem disabled>
                <CircularProgress size={20} /> Searching...
              </MenuItem>
            ) : clients.length > 0 ? (
              clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.first_name} {client.last_name} ({client.email})
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>
                {searchQuery.trim() !== '' ? 'No clients found' : 'Start typing to search for clients'}
              </MenuItem>
            )}
          </TextField>
        </Box>

        {/* Car Model Field */}
        <Box mb={2}>
          <TextField
            label="Car Model"
            value={carModel}
            onChange={(e) => setCarModel(e.target.value)}
            fullWidth
            placeholder="Enter car model"
          />
        </Box>

        {/* Notes Field */}
        <Box mb={2}>
          <TextField
            label="Notes"
            multiline
            rows={3}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            placeholder="Add any additional notes about the booking"
          />
        </Box>

        {/* Display Additional Information */}
        {availableSlots?.additional_information?.map((info, index) => (
          <Box key={index} mb={2}>
            <Typography variant="h6">{info.name}</Typography>
            <TextField
              fullWidth
              margin="normal"
              placeholder={info.description}
              value={additionalInfo[info.id] || ''}
              onChange={(e) => handleAdditionalInfoChange(info.id, e.target.value)}
            />
          </Box>
        ))}

        {/* Display Available Slots */}
        <List>
          {availableSlots?.available_slots && availableSlots.available_slots.length > 0 ? (
            availableSlots.available_slots.map((slot, index) => (
              <ListItem 
                button 
                key={index} 
                onClick={() => onSlotSelect(slot)}
                selected={selectedSlot === slot}
              >
                <ListItemText 
                  primary={`${moment(slot.start_time).format('MMMM D, YYYY')}`} 
                  secondary={`${moment(slot.start_time).format('h:mm A')} - ${moment(slot.end_time).format('h:mm A')} (${slot.available_slots} slots)`} 
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No available slots" />
            </ListItem>
          )}
        </List>
      </DialogContent>

      {/* Action Buttons */}
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        {canCreate && (
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={!selectedClient || !selectedSlot || !carModel}>
            Book Appointment
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BookingDialog;
