import React, { useState, useMemo } from 'react';
import { 
  Box, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem,
  Button, Collapse, Badge, useMediaQuery, useTheme, Typography
} from '@mui/material';
import { Search, Filter, ChevronDown, ChevronUp } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const ChatFiltersSection = ({ filters, handleFilterChange }) => {
  const [filtersVisible, setFiltersVisible] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDateChange = (index, value) => {
    const newDateRange = [...filters.date_range];
    newDateRange[index] = value ? new Date(value) : null;
    handleFilterChange('date_range', newDateRange);
  };

  const toggleFilters = () => setFiltersVisible(!filtersVisible);

  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (filters.is_connected !== null) count++;
    if (filters.human_control !== null) count++;
    if (filters.date_range[0] || filters.date_range[1]) count++;
    return count;
  }, [filters]);

  return (
    <Box sx={{ mb: 3 }}>
     
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search by name, email, or number..."
        value={filters.search}
        onChange={(e) => handleFilterChange('search', e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search size={20} />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 2 }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          {activeFiltersCount > 0 ? `${activeFiltersCount} filter${activeFiltersCount > 1 ? 's' : ''} applied` : 'No filters applied'}
        </Typography>
        <Badge badgeContent={activeFiltersCount} color="primary" overlap="circular" variant="dot">
          <Button
            variant={filtersVisible || activeFiltersCount > 0 ? "contained" : "outlined"}
            onClick={toggleFilters}
            startIcon={<Filter size={20} />}
            endIcon={filtersVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            size="small"
          >
            {filtersVisible ? "Hide Filters" : "Show Filters"}
          </Button>
        </Badge>
      </Box>

      <Collapse in={filtersVisible}>
        <AnimatePresence>
          {filtersVisible && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mt: 2 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Connection Status</InputLabel>
                  <Select
                    value={filters.is_connected === null ? '' : filters.is_connected.toString()}
                    onChange={(e) => handleFilterChange('is_connected', e.target.value === '' ? null : e.target.value === 'true')}
                    label="Connection Status"
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="true">Connected</MenuItem>
                    <MenuItem value="false">Disconnected</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Human Control</InputLabel>
                  <Select
                    value={filters.human_control === null ? '' : filters.human_control.toString()}
                    onChange={(e) => handleFilterChange('human_control', e.target.value === '' ? null : e.target.value === 'true')}
                    label="Human Control"
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mt: 2 }}>
                <TextField
                  label="Start Date"
                  type="date"
                  value={filters.date_range[0] ? filters.date_range[0].toISOString().split('T')[0] : ''}
                  onChange={(e) => handleDateChange(0, e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
                <TextField
                  label="End Date"
                  type="date"
                  value={filters.date_range[1] ? filters.date_range[1].toISOString().split('T')[0] : ''}
                  onChange={(e) => handleDateChange(1, e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Collapse>
    </Box>
  );
};

export default ChatFiltersSection;