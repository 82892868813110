import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/contexts/AuthContext';
import Layout from './components/layout/Layout';
import FormSubmit from './components/formbuilder/FormSubmit';
import FormBuilder from './components/formbuilder/formBuilder';
import FormList from './pages/FormList';
import FormResponses from './components/formbuilder/FormResponses';
import './App.css';
import PropTypes from 'prop-types';

import WarehouseList from './components/gestionale/WarehouseList';
import WarehouseBuilder from './components/gestionale/WarehouseBuilder';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import PaginaUtenti from './components/users/PaginaUtenti';
import PaginaAziende from './pages/PaginaAziende';
import PaginaRuoli from './components/roles/PaginaRuoli';
import DynamicProductForm from './components/gestionale/DynamicProductForm';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import PublicFormPage from './components/formbuilder/PublicForm';
import ProductListByCategory from './components/gestionale/ProductListByCategory';
import CarStockList from './components/carstock/StockPage';
import ClientManagement from './components/crm/ClientManagement';
import ClientDetailsPage from './components/crm/ClientDetailsPage';
import CustomerSegmentsPage from './components/crm/ClientSegmentPage';
import CalendarPage from './components/booking/CalendarPage';
import AvailabilityManagementPage from './components/booking/settings/AvaibilityManagementPage';
import CarEdit from './components/carstock/CarEdit';
import ChatsPage from './components/chats/ChatPage';
import UserManagementPage from './components/users/UserManagementpage';

const theme = createTheme({
  typography: {
   "fontFamily": `"Poppins", sans-serif`,
  },
  palette: {
    primary: {
      main: '#1976d2',
      dark: '#115293',
    },
    secondary: {
      main: '#f50057',
    },
  }
});

const ProtectedRoute = ({ children, requiredApp }) => {
  const { user, loading, appAccess, userRole } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // Bypass permission checks for superadmin
  if (userRole === 'superadmin') {
    // Give superadmin full permissions
    const fullPermissions = {
      can_view: true,
      can_edit: true,
      can_delete: true,
      can_create: true
    };

    // Clone the child element and pass fullPermissions as props
    const childrenWithProps = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { permissions: fullPermissions });
      }
      return child;
    });

    return <Layout>{childrenWithProps}</Layout>;
  }

  // If not superadmin, apply normal permission checks
  let permissions = {
    can_view: true,
    can_edit: true,
    can_delete: true,
    can_create: true
  };

  if (requiredApp && requiredApp !== 'Required') {
    const appPermissions = appAccess && appAccess[requiredApp];
    if (!appPermissions || !appPermissions.can_view) {
      // Redirect to dashboard if user doesn't have view permission
      return <Navigate to="/dashboard" replace />;
    }
    permissions = {
      can_view: appPermissions.can_view,
      can_edit: appPermissions.can_edit,
      can_delete: appPermissions.can_delete,
      can_create: appPermissions.can_create
    };
  }

  // Clone the child element and pass permissions as props for non-superadmin users
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { permissions });
    }
    return child;
  });

  return <Layout>{childrenWithProps}</Layout>;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  requiredApp: PropTypes.string,
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/publicform/:formId" element={<PublicFormPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={
                <ProtectedRoute requiredApp="Required">
                  <Dashboard />
                </ProtectedRoute>
              } />
              <Route path="/dashboard" element={
                <ProtectedRoute requiredApp="Required">
                  <Dashboard />
                </ProtectedRoute>
              } />
              <Route path="/car_stock" element={
                <ProtectedRoute requiredApp="car_stock">
                  <CarStockList />
                </ProtectedRoute>
              } />
              <Route 
                path="/cars/:id/edit" 
                element={
                  <ProtectedRoute requiredApp="car_stock">
                    <CarEdit />
                  </ProtectedRoute>
                } 
              />
              <Route path="/clients" element={
                <ProtectedRoute requiredApp="crm_app">
                  <ClientManagement />
                </ProtectedRoute>
              } />
              <Route path="/chats" element={
                <ProtectedRoute requiredApp="ai_app">
                  <ChatsPage />
                </ProtectedRoute>
              } />
              <Route path="/clients/:id" element={
                <ProtectedRoute requiredApp="crm_app">
                  <ClientDetailsPage />
                </ProtectedRoute>
              } />
              <Route path="/clients/segments" element={
                <ProtectedRoute requiredApp="crm_app">
                  <CustomerSegmentsPage />
                </ProtectedRoute>
              } />
              <Route path="/booking" element={
                <ProtectedRoute requiredApp="booking">
                  <CalendarPage />
                </ProtectedRoute>
              } />
              <Route path="/booking/availability" element={
                <ProtectedRoute requiredApp="booking">
                  <AvailabilityManagementPage />
                </ProtectedRoute>
              } />
              <Route path="/warehouse" element={
                <ProtectedRoute requiredApp="Required">
                  <WarehouseList />
                </ProtectedRoute>
              } />
              <Route path="/warehouses/new" element={
                <ProtectedRoute requiredApp="Required">
                  <WarehouseBuilder />
                </ProtectedRoute>
              } />
              <Route path="/warehouses/:warehouseId/edit" element={
                <ProtectedRoute requiredApp="Required">
                  <WarehouseBuilder />
                </ProtectedRoute>
              } />
              <Route path="/warehouses/:warehouseId/products" element={
                <ProtectedRoute requiredApp="Required">
                  <DynamicProductForm />
                </ProtectedRoute>
              } />
              <Route path="/warehouses/:warehouseId/products-by-category" element={
                <ProtectedRoute requiredApp="Required">
                  <ProductListByCategory />
                </ProtectedRoute>
              }/>
              <Route path="/utenti" element={
                <ProtectedRoute requiredApp="user_management">
                  <UserManagementPage />
                </ProtectedRoute>
              } />
              <Route path="/aziende" element={
                <ProtectedRoute requiredApp="Required">
                  <PaginaAziende />
                </ProtectedRoute>
              } />
              <Route path="/ruoli" element={
                <ProtectedRoute requiredApp="user_management">
                  <PaginaRuoli />
                </ProtectedRoute>
              } />
              <Route path="/forms" element={
                <ProtectedRoute requiredApp="forms">
                  <FormList />
                </ProtectedRoute>
              } />
              <Route path="/forms/new" element={
                <ProtectedRoute requiredApp="forms">
                  <FormBuilder />
                </ProtectedRoute>
              } />
              <Route path="/forms/:id/edit" element={
                <ProtectedRoute requiredApp="forms">
                  <FormBuilder />
                </ProtectedRoute>
              } />
              <Route path="/forms/:formId/responses" element={
                <ProtectedRoute requiredApp="forms">
                  <FormResponses />
                </ProtectedRoute>
              } />
              <Route path="/forms/submit/:formId" element={<FormSubmit />} />
            </Routes>
          </Router>
        </AuthProvider>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default App;