import React from 'react';
import { Grid, TextField, Button, FormControl, Select, MenuItem, Checkbox, ListItemText, Paper, Typography, InputLabel } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';

const HolidayForm = ({
  holidayFormData,
  handleHolidayFormChange,
  handleHolidayServiceChange,
  handleHolidayFormSubmit,
  isEditingHoliday,
  services,
}) => {
  const { userRole, appAccess } = useAuth(); // Access user role and permissions
  const permissions = appAccess['booking'] || {}; // Assuming this relates to booking permissions
  const canCreate = permissions.can_create || userRole === 'superadmin'; // Create permission
  const canEdit = permissions.can_edit || userRole === 'superadmin'; // Edit permission

  // Determine if the user has permission to either create or edit
  const canSubmitForm = isEditingHoliday ? canEdit : canCreate;

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Typography variant="h6" gutterBottom>
        {isEditingHoliday ? 'Edit Holiday' : 'Add New Holiday'}
      </Typography>
      <form onSubmit={handleHolidayFormSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              name="name"
              label="Holiday Name"
              value={holidayFormData.name}
              onChange={handleHolidayFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="start_date"
              label="Start Date"
              type="date"
              value={holidayFormData.start_date}
              onChange={handleHolidayFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="end_date"
              label="End Date"
              type="date"
              value={holidayFormData.end_date}
              onChange={handleHolidayFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth disabled={!canSubmitForm}> {/* Disable if no permission */}
              <InputLabel id="select-multiple-services-label">Select Services</InputLabel>
              <Select
                labelId="select-multiple-services-label"
                multiple
                value={holidayFormData.services}
                onChange={handleHolidayServiceChange}
                renderValue={(selected) =>
                  selected
                    .map((serviceId) => {
                      const service = services.find((s) => s.id === serviceId);
                      return service ? service.name : '';
                    })
                    .join(', ')
                }
              >
                {services.map((service) => (
                  <MenuItem key={service.id} value={service.id}>
                    <Checkbox checked={holidayFormData.services.indexOf(service.id) > -1} />
                    <ListItemText primary={service.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {canSubmitForm && ( // Show the button only if the user has permission to submit
              <Button type="submit" variant="contained" color="primary">
                {isEditingHoliday ? 'Update Holiday' : 'Add Holiday'}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default HolidayForm;
