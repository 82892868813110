import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Typography,
  Button,
} from '@mui/material';

const FormSubmit = () => {
  const { formId } = useParams();
  const [form, setForm] = useState(null);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    axios.get(`https://stage-admin.aitomotivelab.com/formbuilder/api/forms/${formId}/`)
      .then(response => setForm(response.data))
      .catch(error => console.error('Error fetching form:', error));
  }, [formId]);

  const handleChange = (id, value) => {
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: false });
  };

  const handleSubmit = () => {
    const fieldResponses = Object.keys(formData).map(fieldId => ({
      field: fieldId,
      value: formData[fieldId],
    }));

    axios.post('https://stage-admin.aitomotivelab.com/formbuilder/api/form-responses/', {
      form: formId,
      field_responses: fieldResponses,
    })
    .then(() => setSubmitted(true))
    .catch(error => console.error('Error submitting form:', error));
  };

  if (submitted) {
    return <Typography variant="h6">Thank you for your submission!</Typography>;
  }

  if (!form) {
    return <Typography variant="h6">Loading form...</Typography>;
  }

  return (
    <Box>
      <Typography variant="h5">{form.title}</Typography>
      <Typography variant="h6">{form.description}</Typography>
      {form.pages.map(page => (
        <Box key={page.id}>
          <Typography variant="h6">{page.label}</Typography>
          <Typography variant="body1">{page.description}</Typography>
          {page.groups.map(group => (
            <Box key={group.id}>
              <Typography variant="h6">{group.label}</Typography>
              {group.fields.map(field => (
                <Box key={field.id}>
                  <Typography variant="body1">{field.description}</Typography>
                  {(() => {
                    switch (field.field_type) {
                      case 'text':
                        return (
                          <TextField
                            label={field.label}
                            fullWidth
                            margin="normal"
                            error={errors[field.id]}
                            helperText={errors[field.id] && 'This field is required'}
                            value={formData[field.id] || ''}
                            onChange={(e) => handleChange(field.id, e.target.value)}
                          />
                        );
                      case 'number':
                        return (
                          <TextField
                            label={field.label}
                            type="number"
                            fullWidth
                            margin="normal"
                            error={errors[field.id]}
                            helperText={errors[field.id] && 'This field is required'}
                            value={formData[field.id] || ''}
                            onChange={(e) => handleChange(field.id, e.target.value)}
                          />
                        );
                      case 'email':
                        return (
                          <TextField
                            label={field.label}
                            type="email"
                            fullWidth
                            margin="normal"
                            error={errors[field.id]}
                            helperText={errors[field.id] && 'This field is required'}
                            value={formData[field.id] || ''}
                            onChange={(e) => handleChange(field.id, e.target.value)}
                          />
                        );
                      case 'textarea':
                        return (
                          <TextField
                            label={field.label}
                            multiline
                            rows={4}
                            fullWidth
                            margin="normal"
                            error={errors[field.id]}
                            helperText={errors[field.id] && 'This field is required'}
                            value={formData[field.id] || ''}
                            onChange={(e) => handleChange(field.id, e.target.value)}
                          />
                        );
                      case 'select':
                        return (
                          <FormControl fullWidth margin="normal" error={errors[field.id]}>
                            <InputLabel>{field.label}</InputLabel>
                            <Select
                              value={formData[field.id] || ''}
                              onChange={(e) => handleChange(field.id, e.target.value)}
                            >
                              {field.options.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors[field.id] && <Typography variant="caption" color="error">This field is required</Typography>}
                          </FormControl>
                        );
                      case 'radio':
                        return (
                          <FormControl component="fieldset" margin="normal" error={errors[field.id]}>
                            <Typography component="legend">{field.label}</Typography>
                            <RadioGroup
                              value={formData[field.id] || ''}
                              onChange={(e) => handleChange(field.id, e.target.value)}
                            >
                              {field.options.map((option) => (
                                <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                              ))}
                            </RadioGroup>
                            {errors[field.id] && <Typography variant="caption" color="error">This field is required</Typography>}
                          </FormControl>
                        );
                      case 'checkbox':
                        return (
                          <FormGroup>
                            <Typography component="legend">{field.label}</Typography>
                            {field.options.map((option) => (
                              <FormControlLabel
                                key={option}
                                control={
                                  <Checkbox
                                    checked={formData[field.id]?.includes(option) || false}
                                    onChange={(e) => {
                                      const value = formData[field.id] || [];
                                      if (e.target.checked) {
                                        handleChange(field.id, [...value, option]);
                                      } else {
                                        handleChange(
                                          field.id,
                                          value.filter((v) => v !== option)
                                        );
                                      }
                                    }}
                                  />
                                }
                                label={option}
                              />
                            ))}
                            {errors[field.id] && <Typography variant="caption" color="error">This field is required</Typography>}
                          </FormGroup>
                        );
                      case 'date':
                        return (
                          <TextField
                            label={field.label}
                            type="date"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            error={errors[field.id]}
                            helperText={errors[field.id] && 'This field is required'}
                            value={formData[field.id] || ''}
                            onChange={(e) => handleChange(field.id, e.target.value)}
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      ))}
      <Box sx={{ mt: 2 }}>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Invia
        </Button>
      </Box>
    </Box>
  );
};

export default FormSubmit;
