import React, { useState } from 'react';
import { Box, CssBaseline, useMediaQuery, useTheme } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import PropTypes from 'prop-types';

const Layout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSidebarOpen = (open) => {
    setIsOpen(open);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      bgcolor: darkMode ? '#121212' : '#F5F5F5', 
      minHeight: '100vh',
      color: darkMode ? '#FFFFFF' : 'inherit'
    }}>
      <CssBaseline />
      <Header 
        toggleSidebar={handleDrawerToggle} 
        isSidebarOpen={mobileOpen} 
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
      />
      <Sidebar 
        mobileOpen={mobileOpen} 
        handleDrawerToggle={handleDrawerToggle}
        isOpen={isOpen}
        handleSidebarOpen={handleSidebarOpen}
        darkMode={darkMode}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${isOpen ? 240 : 73}px)` },
          ml: { md: `${isOpen ? 240 : 73}px` },
          mt: { xs: '64px', md: '80px' },
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;