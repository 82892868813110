import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useAuth } from '../../../contexts/AuthContext';

const EmailTemplateList = ({ emailTemplates = [], handleEditEmailTemplate, handleDeleteEmailTemplate, services = [] }) => {
  const { userRole, appAccess } = useAuth(); // Access user role and permissions
  const permissions = appAccess['booking'] || {}; // Assuming email templates are related to booking permissions
  const canEdit = permissions.can_edit || userRole === 'superadmin'; // Edit permission
  const canDelete = permissions.can_delete || userRole === 'superadmin'; // Delete permission

  if (!Array.isArray(emailTemplates) || emailTemplates.length === 0) {
    return <Typography>No email templates available.</Typography>;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Service</TableCell>
            <TableCell>Channel</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {emailTemplates.map((template) => (
            <TableRow key={template.id}>
              <TableCell>{services.find(s => s.id === template.service)?.name || 'Unknown Service'}</TableCell>
              <TableCell>{template.channel}</TableCell>
              <TableCell>{template.status}</TableCell>
              <TableCell>{template.subject}</TableCell>
              <TableCell>
                {canEdit && (
                  <IconButton onClick={() => handleEditEmailTemplate(template)} aria-label="Edit" color="primary">
                    <EditIcon />
                  </IconButton>
                )}
                {canDelete && (
                  <IconButton onClick={() => handleDeleteEmailTemplate(template.id)} aria-label="Delete" color="error">
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EmailTemplateList;
