import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { 
  Container, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  CircularProgress, 
  Tabs, 
  Tab, 
  Box,
  Alert
} from '@mui/material';

const API_BASE_URL = 'https://stage-admin.aitomotivelab.com/gestionale/api';

const ProductListByCategory = () => {
  const { warehouseId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(0);

  useEffect(() => {
    fetchData();
  }, [warehouseId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);

      const warehouseResponse = await axios.get(`${API_BASE_URL}/warehouses/${warehouseId}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      const categoriesData = warehouseResponse.data.categories || [];
      if (!Array.isArray(categoriesData)) {
        throw new Error('Invalid categories data received from the server');
      }
      setCategories(categoriesData);

      const productsResponse = await axios.get(`${API_BASE_URL}/products/?warehouse=${warehouseId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      const productsData = Array.isArray(productsResponse.data) ? productsResponse.data : [];
      console.log(productsResponse)
      setProducts(productsData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message || 'An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setCurrentCategory(newValue);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (categories.length === 0) {
    return <Alert severity="info">No categories found for this warehouse.</Alert>;
  }

  const currentCategoryData = categories[currentCategory] || {};
  const currentCategoryProducts = products.filter(product => {
    const productProperties = product.properties || [];
    const categoryPropertyGroups = currentCategoryData.property_groups || [];

    return productProperties.some(prop => 
      categoryPropertyGroups.some(group => 
        (group.properties || []).some(categoryProp => 
          categoryProp.id === prop.property
        )
      )
    );
  });

  const renderTableHeaders = () => {
    const propertyGroups = currentCategoryData.property_groups || [];
    return (
      <TableRow>
        <TableCell>Unique Key</TableCell>
        {propertyGroups.flatMap(group => 
          (group.properties || []).map(prop => (
            <TableCell key={prop.id}>{prop.name}</TableCell>
          ))
        )}
      </TableRow>
    );
  };

  const renderTableRows = () => {
    return currentCategoryProducts.map(product => (
      <TableRow key={product.id}>
        <TableCell>{product.unique_key}</TableCell>
        {(currentCategoryData.property_groups || []).flatMap(group => 
          (group.properties || []).map(prop => {
            const productProp = (product.properties || []).find(p => p.property === prop.id);
            return (
              <TableCell key={prop.id}>
                {productProp ? productProp.value : '-'}
              </TableCell>
            );
          })
        )}
      </TableRow>
    ));
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Products by Category - Warehouse ID: {warehouseId}
      </Typography>
      <Tabs value={currentCategory} onChange={handleCategoryChange}>
        {categories.map((category, index) => (
          <Tab key={category.id} label={category.name} />
        ))}
      </Tabs>
      <Box mt={3}>
        {currentCategoryProducts.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                {renderTableHeaders()}
              </TableHead>
              <TableBody>
                {renderTableRows()}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Alert severity="info">No products found for this category.</Alert>
        )}
      </Box>
    </Container>
  );
};

export default ProductListByCategory;