import React from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, List, ListItem, ListItemText, Box } from '@mui/material';

const ServiceDialog = ({ open, services, startDate, endDate, onStartDateChange, onEndDateChange, onClose, onServiceSelect }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select a Service and Date Range</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={onStartDateChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={onEndDateChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <List>
          {services.map((service) => (
            <ListItem button key={service.id} onClick={() => onServiceSelect(service.id)}>
              <ListItemText primary={service.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default ServiceDialog;
