import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Typography,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  Grow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AddCircleOutline, ErrorOutline, ArrowBack, Edit, Delete } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#f50057',
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#f44336',
    },
  },
  typography: {
    h4: {
      fontWeight: 'bold',
      color: '#333',
    },
    h6: {
      fontWeight: '500',
      color: '#555',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'transform 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        },
      },
    },
  },
});

const API_BASE_URL = 'https://stage-admin.aitomotivelab.com/crm/api';

const CustomerSegmentsPage = () => {
  const navigate = useNavigate();
  const [segments, setSegments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState('create');
  const [currentSegment, setCurrentSegment] = useState({
    id: null,
    name: '',
    description: '',
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [formErrors, setFormErrors] = useState({});
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [segmentToDelete, setSegmentToDelete] = useState(null);

  
  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['crm_app'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canCreate = permissions.can_create || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';


  useEffect(() => {
    fetchSegments();
  }, []);

  const fetchSegments = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/Client-segments/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Fetched segments:', response.data);
      setSegments(response.data);
    } catch (error) {
      console.error('Error fetching segments:', error);
      setSnackbar({ open: true, message: 'Failed to fetch segments', severity: 'error' });
      setSegments([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrUpdateSegment = async () => {
    if (!validateForm()) return;

    try {
      const token = localStorage.getItem('token');
      if (dialogMode === 'create') {
        await axios.post(`${API_BASE_URL}/Client-segments/`, currentSegment, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSnackbar({ open: true, message: 'Segment created successfully', severity: 'success' });
      } else {
        await axios.put(`${API_BASE_URL}/Client-segments/${currentSegment.id}/`, currentSegment, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSnackbar({ open: true, message: 'Segment updated successfully', severity: 'success' });
      }
      setDialogOpen(false);
      resetCurrentSegment();
      fetchSegments();
    } catch (error) {
      console.error('Error creating/updating segment:', error);
      setSnackbar({ open: true, message: `Failed to ${dialogMode} segment`, severity: 'error' });
    }
  };

  const handleDeleteSegment = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_BASE_URL}/Client-segments/${segmentToDelete}/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSnackbar({ open: true, message: 'Segment deleted successfully', severity: 'success' });
      fetchSegments();
    } catch (error) {
      console.error('Error deleting segment:', error);
      setSnackbar({ open: true, message: 'Failed to delete segment', severity: 'error' });
    } finally {
      setDeleteConfirmOpen(false);
      setSegmentToDelete(null);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!currentSegment.name.trim()) errors.name = 'Name is required';
    if (!currentSegment.description.trim()) errors.description = 'Description is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const resetCurrentSegment = () => {
    setCurrentSegment({
      id: null,
      name: '',
      description: '',
    });
    setFormErrors({});
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentSegment(prev => ({ ...prev, [name]: value }));
    setFormErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenCreateDialog = () => {
    setDialogMode('create');
    resetCurrentSegment();
    setDialogOpen(true);
  };

  const handleOpenEditDialog = (segment) => {
    setDialogMode('edit');
    setCurrentSegment({
      id: segment.id || null,
      name: segment.name || '',
      description: segment.description || '',
    });
    setDialogOpen(true);
  };

  const handleOpenDeleteConfirm = (segmentId) => {
    setSegmentToDelete(segmentId);
    setDeleteConfirmOpen(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', maxWidth: 1200, margin: 'auto', p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Customer Segments
        </Typography>
        <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={() => navigate(-1)}
            sx={{ mr: 2 }}
          >
            Go Back
          </Button>
        </Box>
        
        {canCreate && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutline />}
            onClick={handleOpenCreateDialog}
            sx={{ mb: 4 }}
          >
            Create New Segment
          </Button>
        )}

        <Typography variant="h6" component="h2" gutterBottom>
          Existing Segments
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress color="primary" size={60} />
          </Box>
        ) : segments && segments.length > 0 ? (
          <Grid container spacing={4}>
            {segments.map((segment) => (
              <Grow in key={segment.id}>
                <Grid item xs={12} sm={6} md={4}>
                  <Card sx={{ boxShadow: 4, '&:hover': { boxShadow: 6 } }}>
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {segment.name || 'Unnamed Segment'}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {segment.description || 'No description'}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                        Number of Clients: {segment.client_count || 0}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {canEdit && (
                        <Button
                          size="small"
                          startIcon={<Edit />}
                          onClick={() => handleOpenEditDialog(segment)}
                        >
                          Edit
                        </Button>
                      )}
                      {canDelete && (
                        <Button
                          size="small"
                          color="error"
                          startIcon={<Delete />}
                          onClick={() => handleOpenDeleteConfirm(segment.id)}
                        >
                          Delete
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              </Grow>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1" color="textSecondary">
            No segments found. Create a new segment to get started.
          </Typography>
        )}
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>{dialogMode === 'create' ? 'Create New Segment' : 'Edit Segment'}</DialogTitle>
        <DialogContent>
          <TextField
            name="name"
            label="Segment Name"
            value={currentSegment.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            error={!!formErrors.name}
            helperText={formErrors.name}
          />
          <TextField
            name="description"
            label="Description"
            value={currentSegment.description}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            multiline
            rows={3}
            error={!!formErrors.description}
            helperText={formErrors.description}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCreateOrUpdateSegment} color="primary" variant="contained">
            {dialogMode === 'create' ? 'Create Segment' : 'Update Segment'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this segment? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteSegment} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        TransitionComponent={Slide}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.severity === 'success' ? (
            <AddCircleOutline sx={{ mr: 1 }} />
          ) : (
            <ErrorOutline sx={{ mr: 1 }} />
          )}
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default CustomerSegmentsPage;
