import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from '@mui/material';
import ConditionTree from './ConditionTree';

const PropertyGroupDialog = ({ 
  isOpen, 
  onClose, 
  propertyGroup, 
  setPropertyGroup, 
  onSave, 
  isEditing, 
  fields, 
  categories 
}) => {
  const sanitizedGroup = useMemo(() => ({
    property_group_id: propertyGroup?.property_group_id || '',
    name: propertyGroup?.name || '',
    description: propertyGroup?.description || '',
    category_id: propertyGroup?.category_id || '',
    conditions: propertyGroup?.conditions || [],
    rules: {
      is_visible: propertyGroup?.rules?.is_visible ?? true,
      is_collapsible: propertyGroup?.rules?.is_collapsible ?? false,
      is_required: propertyGroup?.rules?.is_required ?? false,
      is_repeatable: propertyGroup?.rules?.is_repeatable ?? false,
    },
    order: propertyGroup?.order || 0,
    properties: propertyGroup?.properties || [],
  }), [propertyGroup]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPropertyGroup(prevGroup => ({
      ...prevGroup,
      [name]: value,
    }));
  };

  const handleRulesChange = (e) => {
    const { name, checked } = e.target;
    setPropertyGroup(prevGroup => ({
      ...prevGroup,
      rules: {
        ...prevGroup.rules,
        [name]: checked,
      },
    }));
  };

  const handleConditionsChange = (newConditions) => {
    setPropertyGroup(prevGroup => ({
      ...prevGroup,
      conditions: newConditions,
    }));
  };

  const handlePropertiesChange = (event) => {
    const {
      target: { value },
    } = event;
    setPropertyGroup(prevGroup => ({
      ...prevGroup,
      properties: Array.isArray(value) ? value : value.split(','),
    }));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{isEditing ? 'Edit Property Group' : 'Add Property Group'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Group Name"
          name="name"
          type="text"
          fullWidth
          value={sanitizedGroup.name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Group Description"
          name="description"
          type="text"
          fullWidth
          multiline
          rows={2}
          value={sanitizedGroup.description}
          onChange={handleChange}
        />
        
        {categories.length > 0 && (
          <FormControl fullWidth margin="dense">
            <InputLabel>Category</InputLabel>
            <Select
              name="category_id"
              value={sanitizedGroup.category_id}
              onChange={handleChange}
            >
              {categories.map((category) => (
                <MenuItem key={category.category_id} value={category.category_id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {fields.length > 0 && (
          <FormControl fullWidth margin="dense">
            <InputLabel>Properties</InputLabel>
            <Select
              multiple
              name="properties"
              value={sanitizedGroup.properties}
              onChange={handlePropertiesChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={fields.find(field => field.property_id === value)?.name || value} />
                  ))}
                </Box>
              )}
            >
              {fields.map((field) => (
                <MenuItem key={field.property_id} value={field.property_id}>
                  {field.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <TextField
          margin="dense"
          label="Order"
          name="order"
          type="number"
          fullWidth
          value={sanitizedGroup.order}
          onChange={handleChange}
        />
        
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Group Rules</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={sanitizedGroup.rules.is_visible}
                onChange={handleRulesChange}
                name="is_visible"
              />
            }
            label="Visible"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sanitizedGroup.rules.is_collapsible}
                onChange={handleRulesChange}
                name="is_collapsible"
              />
            }
            label="Collapsible"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sanitizedGroup.rules.is_required}
                onChange={handleRulesChange}
                name="is_required"
              />
            }
            label="Required"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sanitizedGroup.rules.is_repeatable}
                onChange={handleRulesChange}
                name="is_repeatable"
              />
            }
            label="Repeatable"
          />
        </Box>
        
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Group Conditions</Typography>
          <ConditionTree
            tree={sanitizedGroup.conditions}
            fields={fields}
            onTreeChange={handleConditionsChange}
            showApiCondition={true}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave(sanitizedGroup)}>{isEditing ? 'Update' : 'Add'} Group</Button>
      </DialogActions>
    </Dialog>
  );
};

PropertyGroupDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  propertyGroup: PropTypes.shape({
    property_group_id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    category_id: PropTypes.string,
    conditions: PropTypes.array,
    rules: PropTypes.shape({
      is_visible: PropTypes.bool,
      is_collapsible: PropTypes.bool,
      is_required: PropTypes.bool,
      is_repeatable: PropTypes.bool,
    }),
    order: PropTypes.number,
    properties: PropTypes.arrayOf(PropTypes.string),
  }),
  setPropertyGroup: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    property_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  categories: PropTypes.arrayOf(PropTypes.shape({
    category_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

PropertyGroupDialog.defaultProps = {
  propertyGroup: {},
  fields: [],
  categories: [],
};

export default PropertyGroupDialog;
