import React from 'react';
import { Calendar } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const CalendarComponent = ({ localizer, events, eventStyleGetter, onSelectEvent, views, view, onView }) => {
  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: '100%', width: '100%' }}
      eventPropGetter={eventStyleGetter}
      onSelectEvent={onSelectEvent}
      views={views}
      view={view}
      onView={onView}
      tooltipAccessor={(event) => event.title}
    />
  );
};

export default CalendarComponent;
