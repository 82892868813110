import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  CircularProgress,
  Tooltip,
  Chip,
  Fade,
  createTheme,
  ThemeProvider,
  CssBaseline,
  Grid,
  Container,
  Card,
  Divider,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Add, DragIndicator, Save, KeyboardBackspaceRounded  } from '@mui/icons-material';
import FieldDialog from './FieldDialog';
import GroupDialog from './GroupDialog';
import ConditionTree from './ConditionTree';
import { motion, AnimatePresence } from 'framer-motion';
import ItalianLocationFields from './ItalianLocationFields';
import { Trash2, Pencil, Bolt, StickyNote } from 'lucide-react';



const API_BASE_URL = 'https://stage-admin.aitomotivelab.com/formbuilder';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4361EE',
    },
    secondary: {
      main: '#4895EF',
    },
    third: {
      main: '#4CC9F0',
    },
    background: {
      main: 'rgba(163, 174, 208, 0.05)'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#757575',
          '&: hover': {
            boxShadow: 'none',
            color: '#4895EF',
            backgroundColor: 'rgba(163, 174, 208, 0.05)',
          }
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          flexGrow: 1,
          boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.05)',
        }
      },
    }
  }
});

const FormBuilder = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formTitle, setFormTitle] = useState('');
  const [formDescription, setFormDescription] = useState('');
  const [isPublic, setFormisPublic] = useState(true);
  const [formPages, setFormPages] = useState([]);
  const [includeUserInfo, setIncludeUserInfo] = useState(true);
  const [selectedPage, setSelectedPage] = useState(null);
  const [isAddingField, setIsAddingField] = useState(false);
  const [isAddingPage, setIsAddingPage] = useState(false);
  const [isAddingGroup, setIsAddingGroup] = useState(false);
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);

  
  const [newField, setNewField] = useState({
    field_id: '',
    label: '',
    description: '',
    field_type: 'text',
    options: '',
    conditions: [],
    required: false,
    keyword: '',
    group_id: '',
    next_page_id: '',
    conditional_next_pages: [],
    order: 0
  });
  
  const [newPage, setNewPage] = useState({
    page_id: '',
    label: '',
    description: '',
    conditions: [],
    groups: [],
    order: 0
  });
  
  const [newGroup, setNewGroup] = useState({
    group_id: '',
    label: '',
    description: '',
    fields: [],
    order: 0,
    rules: {
      required: false,
      min_fields: 0,
      max_fields: null
    }
  });
  const [editingGroup, setEditingGroup] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isLoading, setIsLoading] = useState(false);
  const [pageConditions, setPageConditions] = useState([]);
  const [groupConditions, setGroupConditions] = useState({});

  useEffect(() => {
    if (id) {
      fetchForm();
    }
  }, [id]);

  function generateRandomToken() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

  const fetchForm = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_BASE_URL}/api/forms/${id}/`, {
        headers: {
          //'Authorization': `Bearer ${localStorage.getItem("token")}`
          'Authorization': `Bearer ${localStorage.getItem("token")}`
          
        }
      });
      const formData = response.data;
      setFormTitle(formData.title);
      setFormDescription(formData.description);
      setFormisPublic(true)
      const parsedPages = formData.pages.map(page => ({
        ...page,
        page_id: parseInt(page.page_id),
        conditions: JSON.parse(page.conditions || '[]'),
        groups: page.groups.map(group => ({
          ...group,
          group_id: parseInt(group.group_id),
          rules: group.rules ? JSON.parse(group.rules) : { required: false, min_fields: 0, max_fields: null },
          conditions: JSON.parse(group.conditions || '[]'),
          fields: group.fields.map(field => ({
            ...field,
            field_id: parseInt(field.field_id),
            field_type: field.field_type || field.type,
            group_id: parseInt(group.group_id),
            conditions: JSON.parse(field.conditions || '[]'),
            conditional_next_pages: JSON.parse(field.conditional_next_pages || '[]')
          }))
        }))
      }));
      setFormPages(parsedPages);
      setIncludeUserInfo(parsedPages.some(page => page.label === 'Informazioni Utente'));
      setSelectedPage(parsedPages[0]?.page_id || null);
      
      setPageConditions(parsedPages[0]?.conditions || []);
      const initialGroupConditions = {};
      parsedPages.forEach(page => {
        page.groups.forEach(group => {
          initialGroupConditions[group.group_id] = group.conditions || [];
        });
      });
      setGroupConditions(initialGroupConditions);
      
      setIsLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching form data', severity: 'error' });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (formPages.length > 0 && !selectedPage) {
      setSelectedPage(formPages[0].page_id);
    }
  }, [formPages, selectedPage]);

  useEffect(() => {
    if (includeUserInfo && !formPages.some(page => page.label === 'Informazioni Utente')) {
      addUserInfoPage();
    } else if (!includeUserInfo) {
      removeUserInfoPage();
    }
  }, [includeUserInfo, formPages]);

  const addUserInfoPage = () => {
    const newPageId = Math.max(...formPages.map(page => page.page_id), 0) + 1;
    const newGroupId = Math.max(...formPages.flatMap(page => page.groups.map(group => group.group_id)), 0) + 1;
    const newFieldId = Math.max(...formPages.flatMap(page => 
      page.groups.flatMap(group => group.fields.map(field => field.field_id))
    ), 0) + 1;
  
    const userTypeFieldId = newFieldId;
    const userInfoPage = {
      page_id: newPageId,
      label: 'Informazioni Utente',
      description: ' ',
      conditions: [],
      order: 1,
      groups: [
        {
          group_id: newGroupId,
          label: 'Informazioni Utente',
          order: 1,
          fields: [
            {
              field_id: userTypeFieldId,
              label: "Rappresenti un'azienda o un privato?",
              field_type: 'radio',
              keyword:'is_company',
              options: 'Company,Individual',
              required: true,
              order: 1,
              conditions: []
            },
            {
              field_id: userTypeFieldId + 1,
              label: 'Nome',
              field_type: 'text',
              keyword:'first_name',
              required: true,
              order: 2,
              conditions: [{
                logicalOperator: "AND",
                conditions: [{ field: userTypeFieldId, type: 'equals', value: 'Individual' }],
                fieldId: userTypeFieldId,
                type: 'includes',
                value: 'Individual, Company'
              }]
            },
            {
              field_id: userTypeFieldId + 2,
              label: 'Cognome',
              field_type: 'text',
              keyword:'last_name',
              required: true,
              order: 3,
              conditions: [{
                logicalOperator: "AND",
                conditions: [{ field: userTypeFieldId, type: 'equals', value: 'Individual' }],
                fieldId: userTypeFieldId,
                type: 'equals',
                value: 'Individual'
              }]
            },
            
            
            {
              field_id: userTypeFieldId + 3,
              label: 'Email',
              field_type: 'email',
              required: true,
              keyword:'email',
              order: 4,
              conditions: [{
                logicalOperator: "AND",
                conditions: [{ field: userTypeFieldId, type: 'includes', value: 'Individual, Company' }],
                fieldId: userTypeFieldId,
                type: 'includes',
                value: 'Individual, Company'
              }]
            },
            {
              field_id: userTypeFieldId + 4,
              label: 'Numero di telefono',
              field_type: 'number',
              description:'Utilizzeremo il tuo numero di telefono solo per scopi strettamente necessari',
              keyword:'phone',
              required: true,
              order: 5,
              conditions: [{
                logicalOperator: "AND",
                conditions: [{ field: userTypeFieldId, type: 'includes', value: 'Individual, Company' }],
                fieldId: userTypeFieldId,
                type: 'includes',
                value: 'Individual, Company'
              }]
            },
            {
              field_id: userTypeFieldId + 5,
              label: 'Acceti la nostra privacy policy?',
              field_type: 'checkbox',
              description:'Acconsento al trattamento dei miei dati personali ai sensi del GDPR (Regolamento UE 2016/679) e della normativa nazionale vigente in materia di protezione dei dati personali.',
              keyword:'gdpr',
              required: true,
              order: 6,
              conditions: [{
                logicalOperator: "AND",
                conditions: [{ field: userTypeFieldId, type: 'includes', value: 'Individual, Company' }],
                fieldId: userTypeFieldId,
                type: 'includes',
                value: 'Individual, Company'
              }]
            },
            {
              field_id: userTypeFieldId + 6,
              label: 'Accetti il nostro trattamento dei dati per scopi di marketing?',
              field_type: 'checkbox',
              description:'Acconsento a ricevere comunicazioni di marketing e promozionali tramite email, SMS o altri mezzi di comunicazione elettronica.',
              keyword:'marketing',
              required: true,
              order: 7,
              conditions: [{
                logicalOperator: "AND",
                conditions: [{ field: userTypeFieldId, type: 'includes', value: 'Individual, Company' }],
                fieldId: userTypeFieldId,
                type: 'includes',
                value: 'Individual, Company'
              }]
            },
            
           
          ],
          rules: { required: true, min_fields: 3, max_fields: null }
        }
      ]
    };
  
    setFormPages(prevPages => [userInfoPage, ...prevPages]);
    setSelectedPage(userInfoPage.page_id);
  };

  const removeUserInfoPage = () => {
    setFormPages(prevPages => {
      const newPages = prevPages.filter(page => page.label !== 'Informazioni Utente');
      if (selectedPage === prevPages.find(page => page.label === 'Informazioni Utente')?.page_id) {
        setSelectedPage(newPages.length > 0 ? newPages[0].page_id : null);
      }
      return newPages;
    });
  };



  const handleAddField = (groupId) => {
    const maxFieldId = Math.max(...formPages.flatMap(page => 
      page.groups.flatMap(group => 
        group.fields.map(field => field.field_id)
      )
    ), 0);
    
    setNewField({
      field_id: maxFieldId + 1,
      label: '',
      description: '',
      field_type: 'text',
      options: '',
      conditions: [],
      required: false,
      keyword: '',
      group_id: groupId,
      next_page_id: '',
      conditional_next_pages: [],
      order: 0
    });
    setIsAddingField(true);
  };





  const handleEditGroup = (pageId, groupId) => {
    const page = formPages.find(p => p.page_id === pageId);
    const group = page.groups.find(g => g.group_id === groupId);
    setEditingGroup({ ...group, pageId });
    setIsEditingGroup(true);
  };

  const handleSaveField = () => {
    if (newField.label) {
      const fieldToSave = {
        ...newField,
        options: newField.field_type === 'italian_location' ? 'region,province,municipality,cap' : newField.options,
      };
      setFormPages(prevPages => prevPages.map(page =>
        page.page_id === selectedPage
          ? {
            ...page,
            groups: page.groups.map(group =>
              group.group_id === fieldToSave.group_id
                ? {
                  ...group,
                  fields: group.fields.some(f => f.field_id === fieldToSave.field_id)
                    ? group.fields.map(field =>
                      field.field_id === fieldToSave.field_id ? fieldToSave : field
                    )
                    : [...group.fields, {
                      ...fieldToSave,
                      conditions: Array.isArray(fieldToSave.conditions) ? fieldToSave.conditions : [],
                      conditional_next_pages: Array.isArray(fieldToSave.conditional_next_pages) ? fieldToSave.conditional_next_pages : [],
                      order: group.fields.length + 1
                    }]
                }
                : group
            )
          }
          : page
      ));
      setNewField({
        field_id: '',
        label: '',
        description: '',
        field_type: 'text',
        options: '',
        conditions: [],
        required: false,
        keyword: '',
        group_id: '',
        next_page_id: '',
        conditional_next_pages: [],
        order: 0
      });
      setIsAddingField(false);
      setSnackbar({ open: true, message: newField.field_id ? 'Campo aggiornato con successo' : 'Campo aggiunto con successo', severity: 'success' });
    } else {
      setSnackbar({ open: true, message: "E' necessario il titolo del campo", severity: 'error' });
    }
  };

  const handleSavePage = () => {
    if (newPage.label) {
      const pageToAdd = {
        ...newPage,
        page_id: newPage.page_id,
        order: formPages.length + 1,
        groups: []
      };
      setFormPages(prevPages => [...prevPages, pageToAdd]);
      setNewPage({ page_id: '', label: '', description: '', conditions: [], groups: [], order: 0 });
      setIsAddingPage(false);
      setSnackbar({ open: true, message: 'Pagina aggiunta con successo', severity: 'success' });
    } else {
      setSnackbar({ open: true, message: "E' necessario il titolo della pagina", severity: 'error' });
    }
  };
  const handleSaveGroup = () => {
    if (newGroup.label) {
      setFormPages(prevPages => prevPages.map(page =>
        page.page_id === selectedPage
          ? {
              ...page,
              groups: [...page.groups, {
                ...newGroup,
                order: page.groups.length + 1,
                conditions: newGroup.conditions || []
              }]
            }
          : page
      ));
      setNewGroup({
        group_id: '',
        label: '',
        description: '',
        fields: [],
        order: 0,
        conditions: [],
        rules: {
          required: false,
          min_fields: 0,
          max_fields: null
        }
      });
      setIsAddingGroup(false);
      setSnackbar({ open: true, message: 'Group added successfully', severity: 'success' });
    } else {
      setSnackbar({ open: true, message: 'Group label is required', severity: 'error' });
    }
  };

  const handleSaveGroupEdit = () => {
    if (editingGroup) {
      setFormPages(prevPages => prevPages.map(page =>
        page.page_id === editingGroup.pageId
          ? {
              ...page,
              groups: page.groups.map(group =>
                group.group_id === editingGroup.group_id
                  ? {
                      ...group,
                      label: editingGroup.label,
                      description: editingGroup.description,
                      conditions: editingGroup.conditions || [],
                      rules: editingGroup.rules
                    }
                  : group
              )
            }
          : page
      ));
      setIsEditingGroup(false);
      setEditingGroup(null);
      setSnackbar({ open: true, message: 'Group updated successfully', severity: 'success' });
    }
  };

  const handleDeleteField = (pageId, groupId, fieldId) => {
    setFormPages(prevPages => prevPages.map(page =>
      page.page_id === pageId
        ? { ...page, groups: page.groups.map(group =>
          group.group_id === groupId
            ? { ...group, fields: group.fields.filter(field => field.field_id !== fieldId) }
            : group
        ) }
        : page));
    setSnackbar({ open: true, message: 'Field deleted successfully', severity: 'success' });
  };

  const handleDeleteGroup = (pageId, groupId) => {
    setFormPages(prevPages => prevPages.map(page =>
      page.page_id === pageId
        ? { ...page, groups: page.groups.filter(group => group.group_id !== groupId) }
        : page));
    setGroupConditions(prevConditions => {
      const newConditions = { ...prevConditions };
      delete newConditions[groupId];
      return newConditions;
    });
    setSnackbar({ open: true, message: 'Group deleted successfully', severity: 'success' });
  };

  const handleEditField = (pageId, groupId, fieldId) => {
    const fieldToEdit = formPages
      .find(page => page.page_id === pageId)
      .groups.find(group => group.group_id === groupId)
      .fields.find(field => field.field_id === fieldId);

    setNewField({
      ...fieldToEdit,
      pageId,
      group_id: groupId
    });
    setIsAddingField(true);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const sourceGroupId = source.droppableId;
    const destGroupId = destination.droppableId;

    setFormPages(prevPages => {
      const newPages = [...prevPages];
      const currentPage = newPages.find(page => page.page_id === selectedPage);

      const sourceGroup = currentPage.groups.find(group => group.group_id === sourceGroupId);
      const [reorderedItem] = sourceGroup.fields.splice(source.index, 1);

      if (sourceGroupId === destGroupId) {
        sourceGroup.fields.splice(destination.index, 0, reorderedItem);
      } else {
        const destGroup = currentPage.groups.find(group => group.group_id === destGroupId);
        reorderedItem.group_id = destGroupId;
        destGroup.fields.splice(destination.index, 0, reorderedItem);
      }

      // Update order for all fields
      currentPage.groups.forEach(group => {
        group.fields.forEach((field, index) => {
          field.order = index + 1;
        });
      });

      return newPages;
    });
  };





  const handleSaveForm = async () => {
    if (!formTitle) {
      setSnackbar({ open: true, message: 'Form title is required', severity: 'error' });
      return;
    }
    setIsLoading(true);
    const formData = {
      title: formTitle,
      description: formDescription,
      is_public: isPublic,
      pages: formPages.map((page) => ({
        page_id: parseInt(page.page_id),
        label: page.label,
        description: page.description,
        order: page.order,
        conditions: JSON.stringify(page.conditions || []),
        groups: page.groups.map((group) => ({
          group_id: parseInt(group.group_id),
          label: group.label,
          description: group.description,
          order: group.order,
          rules: JSON.stringify(group.rules || { required: false, min_fields: 0, max_fields: null }),
          conditions: JSON.stringify(groupConditions[group.group_id] || []),
          fields: group.fields.map((field) => ({
            field_id: parseInt(field.field_id),
            label: field.label,
            description: field.description,
            field_type: field.field_type,
            options: field.options,
            conditions: JSON.stringify(field.conditions || []),
            required: field.required,
            keyword: field.keyword,
            next_page_id: field.next_page_id ? parseInt(field.next_page_id) : null,
            conditional_next_pages: JSON.stringify(field.conditional_next_pages || []),
            order: field.order,
          })),
        })),
      })),
    };
  
    try {
      let response;
      if (id) {
        response = await axios.put(`${API_BASE_URL}/api/forms/${id}/`, formData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          }
        });
      } else {
        response = await axios.post(`${API_BASE_URL}/api/forms/`, formData,

          {
            headers: {
              //'Authorization': `Bearer ${localStorage.getItem("token")}`
              'Authorization': `Bearer ${localStorage.getItem("token")}`
              
            }}

        );
      }
      console.log('Form saved:', response.data);
      setSnackbar({ open: true, message: 'Form saved successfully', severity: 'success' });
      navigate('/forms');
    } catch (error) {
      console.error('Error saving form:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        setSnackbar({ open: true, message: `Error saving form: ${JSON.stringify(error.response.data)}`, severity: 'error' });
      } else {
        setSnackbar({ open: true, message: 'Error saving form: Network error', severity: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderField = (field) => {
    switch (field.field_type) {
      case 'text':
      case 'email':
      case 'number':
        return (
          <TextField
            fullWidth
            label={field.label}
            type={field.field_type}
            required={field.required}
            disabled={isPreviewMode}
          />
        );
      case 'textarea':
        return (
          <TextField
            fullWidth
            label={field.label}
            multiline
            rows={4}
            required={field.required}
            disabled={isPreviewMode}
          />
        );
      case 'radio':
      case 'checkbox':
        return (
          <FormControlLabel
            control={
              field.field_type === 'radio' ? (
                <input type="radio" disabled={isPreviewMode} />
              ) : (
                <Checkbox disabled={isPreviewMode} />
              )
            }
            label={field.label}
          />
        );
      case 'italian_location':
        return (
          <ItalianLocationFields
            onChange={(subField, value) => {
              // Handle changes to the Italian location fields
              console.log(`${subField} changed to:`, value);
            }}
            disabled={isPreviewMode}
          />
        );
      default:
        return <Typography>Unsupported field type: {field.field_type}</Typography>;
    }
  };

 


  const renderPreview = () => (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>Form Preview</Typography>
      {formPages.map((page) => (
        <Paper key={page.page_id} elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6">{page.label}</Typography>
          <Typography variant="body2" gutterBottom>{page.description}</Typography>
          {page.groups.map((group) => (
            <Box key={group.group_id} sx={{ mb: 3 }}>
              <Typography variant="subtitle1">{group.label}</Typography>
              <Typography variant="body2" gutterBottom>{group.description}</Typography>
              {group.fields.map((field) => (
                <Box key={field.field_id} sx={{ mb: 2 }}>
                  {renderField(field)}
                </Box>
              ))}
            </Box>
          ))}
        </Paper>
      ))}
    </Box>
  );


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.main }}>
          <Grid container spacing={2} component="main" sx={{ p: {xs: 4, md: 10}, mt: {xs: 10, md: 0} }}>

         

            
        <Grid container>
          <Container sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 5 }}>
              <Card sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'column'}, gap: 5, p: 3, mb: 3 }}>

            {/* Impostazioni Generali */}

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2}}>
                <Bolt size={50} />
                <Typography variant="h4">Impostazioni Generali</Typography>
              </Box>
              <Typography color="#858585">Titolo e descrizione che verranno mostrati all'utente.</Typography>
            </Box>

            

              
                
                {selectedPage && (
                  <Fade in={true}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>

                     

                      <Divider variant="middle" sx={{ mb: 6 }}/>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        {formPages.find(page => page.page_id === selectedPage)?.groups.map(group => (
                          <Droppable key={group.group_id} droppableId={group.group_id}>
                            {(provided) => (


                              <Paper elevation={0} sx={{ p: 2, mb: 2, border: '2px solid #4895EF' }} ref={provided.innerRef} {...provided.droppableProps}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Typography variant="h6">{group.label}</Typography>
                                  <Box sx={{ display: 'flex', gap: 5 }}>
                                    <Tooltip title="Modifica Gruppo">
                                      <IconButton size="small" onClick={() => handleEditGroup(selectedPage, group.group_id)}>
                                        <Pencil />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Elimina Gruppo">
                                      <IconButton size="small" onClick={() => handleDeleteGroup(selectedPage, group.group_id)}>
                                        <Trash2 />
                                      </IconButton>
                                    </Tooltip>
                                    {/* <Tooltip title="Trascina per Riordinare">
                                      <IconButton size="small">
                                        <DragIndicator />
                                      </IconButton>
                                    </Tooltip> */}
                                  </Box>
                                </Box>
                                {group.description && (
                                  <Typography variant="body2" sx={{ mb: 1 }}>{group.description}</Typography>
                                )}
                                
                               
                                <List>
                                  {group.fields.map((field, index) => (
                                    <Draggable key={field.field_id} draggableId={String(field.field_id)} index={index}>
                                      {(provided) => (
                                      <ListItem
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Card sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: '5px', padding: '10px' }}>
                                      <ListItemText
                                        primary={field.label}
                                        secondary={`Type: ${field.field_type} | ${field.required ? 'Required' : 'Optional'}`}
                                      />

                                      <Box sx={{ display: 'flex', gap: 5 }}>
                                        <Tooltip title="Modifica Gruppo">
                                          <IconButton edge="end" aria-label="edit" onClick={() => handleEditField(selectedPage, group.group_id, field.field_id)}>
                                            <Pencil />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Elimina Gruppo">
                                          <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteField(selectedPage, group.group_id, field.field_id)}>
                                            <Trash2 />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Trascina per Riordinare">
                                          <IconButton>
                                            <DragIndicator />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                      </Card>
                                    </ListItem>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}

                            </List>
                            <Button startIcon={<Add />} onClick={() => handleAddField(group.group_id)}>
                              Add Field
                            </Button>
                          </Paper>
                        )}
                      </Droppable>
                    ))}
                  </DragDropContext>
                </Box>
              </Fade>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 3, alignSelf: 'end' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveForm}
                startIcon={<Save />}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Salva'}
              </Button>
            </Box>
          </Card>
        </Container>
        
      </Grid> 

      <AnimatePresence>
        {isPreviewMode && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
          >
            {renderPreview()}
          </motion.div>
        )}
      </AnimatePresence>

      <FieldDialog
        isOpen={isAddingField}
        onClose={() => {
          setIsAddingField(false);
          setNewField({
            field_id: '',
            label: '',
            description: '',
            field_type: 'text',
            options: '',
            conditions: [],
            required: false,
            keyword: '',
            group_id: '',
            next_page_id: '',
            conditional_next_pages: [],
            order: 0
          });
        }}
        field={newField}
        setField={setNewField}
        onSave={handleSaveField}
        pages={formPages}
      />

      <Dialog open={isAddingPage} onClose={() => setIsAddingPage(false)}>
        <DialogTitle>Add New Page</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nome Pagina"
            type="text"
            fullWidth
            value={newPage.label}
            onChange={(e) => setNewPage({ ...newPage, label: e.target.value })}
          />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddingPage(false)}>Cancel</Button>
          <Button onClick={handleSavePage}>Aggiungi Pagina</Button>
        </DialogActions>
      </Dialog>

      <GroupDialog
        isOpen={isAddingGroup || isEditingGroup}
        onClose={() => {
          setIsAddingGroup(false);
          setIsEditingGroup(false);
          setEditingGroup(null);
        }}
        group={isEditingGroup ? editingGroup : newGroup}
        setGroup={isEditingGroup ? setEditingGroup : setNewGroup}
        onSave={isEditingGroup ? handleSaveGroupEdit : handleSaveGroup}
        isEditing={isEditingGroup}
        fields={formPages.flatMap(page => page.groups.flatMap(group => group.fields))}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      </Grid>
    </Box>
    </CssBaseline>
 </ThemeProvider>
);
};

export default FormBuilder;